import { rateServiceCallback, useTrackAnalytics } from '@laborability/commons';
import {
  LBTButton,
  LBTDivider,
  LBTLabel,
  LBTRating,
  LBTSpacer,
  LBTTextField,
  Section,
} from '@laborability/components';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import ThankYouPage from '../../components/ThankYouPage';

export default function RatingPage() {
  const sendReport = useRecoilCallback(rateServiceCallback, []);
  const descriptionMaxLength = 800;
  const [isReportCompleted, setIsReportCompleted] = useState(false);
  const [rate, setRate] = useState(4);
  const [values, setValues] = useState<string>('');
  const [errors, setErrors] = useState<boolean>(false);

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Menu - Valuta il servizio' },
    { key: 'page_location', value: '/menu/valuta-servizio/' },
  ]);

  return (
    <Section>
      {isReportCompleted ? (
        <ThankYouPage
          title="Grazie per la recensione"
          subtitle="Le recensioni di chi usa il nostro servizio sono utilissime, perché ci permettono di conoscere cosa pensano gli utenti e ci aiutano a rendere il nostro prodotto ogni giorno migliore"
        />
      ) : (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="delaDisplay" component="h1">
            Come valuti il nostro servizio?
          </LBTLabel>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="spGroteskSubtitle" component="h2">
            Ci teniamo a sapere cosa ne pensi, così possiamo migliorare
          </LBTLabel>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="bodyText">
            Tocca le stelle per valutare e, se ti va, lasciaci una recensione
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <LBTRating rate={rate} setRate={setRate} />
          <LBTSpacer spacing={4} />
          <LBTDivider />
          <LBTSpacer spacing={4} />
          <LBTLabel variant="bodyText">Scrivi una recensione</LBTLabel>
          <LBTSpacer spacing={2} />
          <LBTTextField
            label="Recensione"
            type="text"
            name="report"
            onChange={value => {
              setErrors(descriptionMaxLength - (value?.length ?? 0) < 0);
              setValues(value ?? '');
            }}
            value={values}
            required
            error={errors}
            helperText={
              errors && !values?.length
                ? 'Campo obbligatorio'
                : `${descriptionMaxLength - (values?.length ?? 0)} caratteri disponibili`
            }
            sx={{ maxWidth: '680px' }}
          />
          <LBTSpacer spacing={4} />
          <LBTButton
            variant="contained"
            onClick={async () => {
              const res = await sendReport({ review: values, value: rate });
              if (res?.data) setIsReportCompleted(true);
            }}
            fullWidth
            maxWidth="680px"
            disabled={errors || !values?.length}
          >
            Invia recensione
          </LBTButton>
          <LBTSpacer spacing={4} />
        </>
      )}
    </Section>
  );
}
