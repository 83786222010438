export default function IconBanner() {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33955 2.5C6.17285 2.5 4.33458 4.14035 4.20382 6.30501C3.91492 11.0927 3.93013 15.8924 4.27832 20.6832C4.39692 22.3265 6.40093 23.095 7.6158 21.964L12.2396 17.6556C12.3102 17.5897 12.4038 17.5529 12.5011 17.5529C12.5984 17.5529 12.692 17.5897 12.7627 17.6556L17.3865 21.964C18.6029 23.095 20.6053 22.3265 20.7239 20.6832C21.0665 15.8977 21.0914 11.0952 20.7984 6.30651C20.6662 4.14035 18.8294 2.5 16.6627 2.5H8.33955Z"
        fill="#282828"
      />
    </svg>
  );
}
