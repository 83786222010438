const IconThankYou = ({ size = 200 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.5578 97.5288C65.5338 91.9288 67.4818 80.9188 76.4868 78.6858C70.0868 72.4578 72.5868 56.3178 84.8778 54.0268C74.4798 41.2398 104.633 22.2448 112.641 41.2998C117.417 31.0068 138.652 39.2128 128.788 52.0998C139.88 49.9688 145.378 65.2998 135.51 71.9398C142.283 74.8188 147.06 84.5728 140.49 91.9028C135.613 88.8248 125.177 86.0968 117.836 85.3818C117.973 80.5598 116.789 75.4118 116.814 73.4618C119.259 72.8278 120.57 71.6878 120.414 69.2278C120.226 66.3578 116.54 65.3128 115.166 67.4708C112.972 65.3228 111.032 60.8968 111.486 57.3378C106.641 59.5868 98.6858 58.7058 95.6758 57.3378C93.2528 63.9048 94.1008 84.6998 104.486 83.3768C104.49 84.3565 104.231 85.3194 103.736 86.1648C87.8818 87.9668 75.5438 93.4288 69.5578 97.5288Z"
        fill="white"
      />
      <path
        d="M25.5459 111.284C75.0589 115.451 133.365 114.392 177.676 109.055C178.645 108.939 178.325 107.492 177.376 107.581C157.514 109.447 138.069 111.056 118.114 111.772C87.2775 112.79 56.4088 112.193 25.6349 109.982C25.4661 109.977 25.302 110.038 25.1769 110.151C25.0518 110.264 24.9754 110.422 24.9639 110.59C24.9524 110.759 25.0066 110.925 25.1151 111.054C25.2236 111.184 25.378 111.266 25.5459 111.284Z"
        fill="white"
      />
      <path
        d="M191.482 154.877C188.846 140.739 187.772 129.072 186.305 114.902C186.285 114.766 186.214 114.643 186.106 114.558C185.998 114.473 185.862 114.433 185.725 114.446C185.589 114.459 185.462 114.523 185.372 114.627C185.282 114.731 185.235 114.865 185.241 115.002C186.083 128.807 187.695 142.554 190.067 156.179C168.038 160.143 145.782 162.729 123.431 163.923C86.5519 166.091 55.1459 164.03 18.5979 160.254C16.3239 144.263 16.9919 133.191 17.8209 116.913C17.8275 116.774 17.7801 116.638 17.6887 116.534C17.5973 116.429 17.469 116.364 17.3306 116.352C17.1922 116.34 17.0545 116.381 16.9463 116.469C16.838 116.556 16.7677 116.681 16.7499 116.819C15.4039 125.373 15.0499 148.164 17.0799 159.495C17.4169 161.371 17.4869 161.529 19.6999 161.788C54.0559 165.824 84.3999 167.919 119.378 166.128C143.034 164.932 166.585 162.175 189.878 157.874C191.864 157.5 191.817 156.669 191.482 154.877Z"
        fill="white"
      />
      <path
        d="M32.108 149.234C32.1002 149.33 32.1114 149.426 32.1412 149.517C32.1709 149.608 32.2185 149.692 32.2811 149.764C32.3438 149.836 32.4202 149.895 32.506 149.938C32.5917 149.98 32.6851 150.005 32.7806 150.011C32.876 150.017 32.9717 150.003 33.062 149.972C33.1523 149.94 33.2354 149.891 33.3064 149.827C33.3774 149.763 33.4349 149.685 33.4755 149.598C33.5162 149.512 33.5391 149.418 33.543 149.322C34.002 141.982 34.116 134.807 35.268 125.947C36.608 126.012 40.222 126.24 41.268 125.731C41.768 125.486 41.754 124.731 41.179 124.709C38.964 124.609 32.221 124.484 28.131 123.709C27.253 123.542 26.531 124.909 28.784 125.293C30.338 125.557 31.909 125.714 33.484 125.836C33.155 129.265 32.707 133.668 32.484 136.958C32.376 138.483 32.043 146.016 32.108 149.234Z"
        fill="white"
      />
      <path
        d="M152.784 136.961C152.539 133.254 151.661 126.575 148.253 124.561C143.121 121.528 140.722 125.898 139.27 130.105C137.822 134.322 137.728 138.887 139.001 143.161C140.101 146.824 143.001 152.553 147.769 150.41C152.201 148.421 153.063 141.189 152.784 136.961ZM147.713 148.733C143.926 151.477 141.337 145.333 140.518 142.581C139.397 138.723 139.45 134.618 140.671 130.791C141.709 127.497 143.484 122.552 147.762 125.998C150.141 127.914 150.921 133.885 151.138 136.858C151.391 140.333 150.893 146.43 147.713 148.733Z"
        fill="white"
      />
      <path
        d="M136.875 125.2C137.13 123.948 136.086 123.8 135.739 124.746C134.399 128.401 132.358 133.346 131.469 135.778C131.144 136.667 132.353 137.353 132.776 136.326C133.908 133.582 136.401 127.525 136.875 125.2Z"
        fill="white"
      />
      <path
        d="M160.24 146.01C156.947 142.71 156.467 129.303 156.24 124.65C156.205 123.938 155.017 124.024 154.992 124.723C154.792 130.34 155.285 143.355 159.018 147.047C159.56 147.63 160.233 148.075 160.982 148.345C161.731 148.614 162.533 148.701 163.322 148.598C164.111 148.494 164.864 148.203 165.518 147.749C166.172 147.296 166.708 146.692 167.081 145.989C168.537 143.416 168.729 138.74 168.594 135.818C168.304 131.479 167.547 127.184 166.336 123.007C166.093 122.101 164.529 122.569 164.752 123.466C166.274 129.577 168.018 137.366 166.264 143.689C165.421 146.714 162.801 148.575 160.24 146.01Z"
        fill="white"
      />
      <path
        d="M175.464 141.691C174.897 135.597 174.247 126.726 173.128 120.756C173.006 120.103 171.728 120.166 171.741 120.856C171.836 125.869 173.206 136.832 173.73 141.871C173.87 143.221 175.665 143.857 175.464 141.691Z"
        fill="white"
      />
      <path
        d="M44.3738 149.464C44.5988 146.84 44.7908 143.308 45.0218 140.311C47.4018 140.423 49.9398 140.646 52.2958 140.696C54.5068 140.74 54.5168 139.288 52.3958 139.16C50.0548 139.017 47.4268 138.942 45.1258 138.917C45.4498 134.683 45.8458 130.11 46.1188 125.463C46.2758 122.787 44.6868 122.868 44.4568 125.414C44.0388 130.02 43.7128 134.614 43.3808 138.896C42.889 138.894 42.3977 138.927 41.9108 138.996C41.1968 139.105 41.1048 139.956 41.8558 140.096C42.3258 140.179 42.8009 140.229 43.2778 140.246C43.0488 143.217 42.7368 146.198 42.5188 149.414C42.3488 151.952 44.1528 152.047 44.3738 149.464Z"
        fill="white"
      />
      <path
        d="M174.417 146.852C174.46 147.051 174.57 147.228 174.729 147.354C174.888 147.48 175.085 147.547 175.288 147.545C175.49 147.542 175.686 147.47 175.841 147.339C175.997 147.209 176.103 147.03 176.141 146.83C176.148 146.345 176.071 145.861 175.915 145.401C175.541 144.563 174.026 144.501 174.276 146.007C174.322 146.289 174.37 146.57 174.417 146.852Z"
        fill="white"
      />
      <path
        d="M54.6849 152.022C55.8589 143.838 57.3709 135.373 58.1909 127.176C58.4339 124.745 56.9529 124.067 56.6759 126.669C55.7839 135.016 54.1429 143.669 52.9949 151.68C52.8609 152.609 54.5499 152.964 54.6849 152.022Z"
        fill="white"
      />
      <path
        d="M90.3089 149.173C87.8929 143.029 83.4209 131.854 80.6729 127.159C80.0959 126.174 78.9389 126.879 79.0539 128.499C79.1539 129.946 79.5469 142.544 80.4089 151.858C80.5089 152.897 81.6289 152.94 81.6299 151.844C81.6299 145.744 80.7889 131.788 80.6519 130.337C83.1039 135.967 86.3979 143.773 89.2019 149.583C89.7059 150.628 90.6499 150.039 90.3089 149.173Z"
        fill="white"
      />
      <path
        d="M61.3529 152.839C62.1069 149.671 63.0869 146.063 63.6939 143.704C65.9639 144.097 68.1489 144.404 70.4349 144.574C71.9769 144.69 71.9889 143.25 70.5759 143.166C68.3795 143.036 66.1913 142.794 64.0199 142.439C65.3649 137.308 67.1329 131.909 68.4289 126.861C68.8069 125.39 67.7459 124.598 67.1689 126.184C65.1489 131.734 63.5379 137.529 62.3039 142.234C61.6359 142.118 61.2799 142.249 61.1909 142.65C61.1019 143.051 61.3909 143.27 62.0039 143.387C61.4239 145.63 59.9279 150.648 59.9209 152.631C59.9179 153.256 61.2009 153.46 61.3529 152.839Z"
        fill="white"
      />
      <path
        d="M76.026 152.486C74.4844 143.912 72.5048 135.422 70.095 127.049C69.765 125.949 68.741 126.586 68.938 127.393C70.238 132.743 70.691 135.549 71.602 139.626C72.316 142.819 73.48 148.334 74.34 152.799C74.494 153.6 76.178 153.335 76.026 152.486Z"
        fill="white"
      />
      <path
        d="M97.5851 127.342C98.1731 135.766 98.2101 144.157 98.7251 151.713C98.8131 152.992 100.325 153.396 100.287 151.626C100.131 144.099 99.9331 135.52 99.2011 127.282C99.0321 125.375 97.4611 125.567 97.5851 127.342Z"
        fill="white"
      />
      <path
        d="M91.7647 126.523C91.3247 129.461 90.8127 145.428 90.8907 151.886C90.8842 151.989 90.899 152.092 90.934 152.19C90.969 152.287 91.0236 152.376 91.0944 152.452C91.1652 152.527 91.2507 152.587 91.3456 152.628C91.4405 152.669 91.5428 152.69 91.6462 152.69C91.7497 152.69 91.852 152.669 91.9469 152.628C92.0418 152.587 92.1273 152.527 92.1981 152.452C92.2689 152.376 92.3235 152.287 92.3585 152.19C92.3935 152.092 92.4083 151.989 92.4018 151.886C92.7018 144.12 93.2067 129.523 92.9227 126.522C92.8953 126.388 92.8227 126.269 92.7171 126.183C92.6116 126.097 92.4795 126.05 92.3433 126.05C92.2072 126.05 92.0752 126.097 91.9698 126.183C91.8644 126.269 91.7919 126.389 91.7647 126.523Z"
        fill="white"
      />
      <path
        d="M110.327 124.9C107.581 130.147 103.196 136.873 100.596 141.689C100.266 142.3 101.428 142.702 101.836 142.152C102.115 141.778 102.384 141.398 102.647 141.014C104.925 144.554 107.102 148.24 109.372 151.814C111.254 154.777 112.938 154.285 111.046 151.314C108.562 147.414 106.093 143.538 103.514 139.697C106.22 135.431 109.527 130.038 111.836 125.619C112.848 123.684 111.201 123.23 110.327 124.9Z"
        fill="white"
      />
      <path
        d="M130.948 137.083C128.661 133.878 125.257 129.099 122.805 125.59C122.488 125.137 121.515 125.46 121.758 126.029C122.619 128.042 128.814 137.14 130.398 138.903C130.646 142.725 130.722 147.783 131.098 151.259C131.091 151.38 131.109 151.501 131.153 151.614C131.196 151.727 131.264 151.83 131.35 151.915C131.436 152 131.54 152.066 131.654 152.107C131.768 152.149 131.889 152.166 132.01 152.157C132.131 152.148 132.248 152.113 132.355 152.055C132.461 151.997 132.554 151.916 132.626 151.819C132.699 151.722 132.75 151.611 132.776 151.493C132.802 151.374 132.803 151.252 132.778 151.133C132.519 148.024 132.347 144.37 132.04 140.762C131.845 138.494 131.683 138.114 130.948 137.083Z"
        fill="white"
      />
      <path
        d="M144.776 50.8234C147.026 47.2734 149.297 43.8854 151.912 40.1794C152.251 39.7004 151.54 38.9324 151.112 39.4384C148.351 42.8317 145.894 46.4625 143.772 50.2884C143.459 50.8534 144.43 51.3684 144.776 50.8234Z"
        fill="white"
      />
      <path
        d="M156.821 87.849C156.17 87.981 155.97 88.888 156.971 88.787C163.429 87.9521 169.837 86.7617 176.164 85.221C177.08 84.911 176.664 84.039 175.984 84.103C175.542 84.145 159.956 87.214 156.821 87.849Z"
        fill="white"
      />
      <path
        d="M154.018 68.9227C159.446 66.3677 175.05 56.3367 177.454 54.2427C177.57 54.1511 177.646 54.0169 177.663 53.8697C177.681 53.7224 177.639 53.5743 177.548 53.4577C177.456 53.3411 177.322 53.2657 177.175 53.2481C177.028 53.2305 176.879 53.2721 176.763 53.3637C174.263 54.9837 158.6 65.0327 153.617 67.9797C153.201 68.2247 153.501 69.1677 154.018 68.9227Z"
        fill="white"
      />
      <path
        d="M29.2009 85.9166C35.5009 87.3986 44.2489 88.7166 51.6509 89.8246C52.2639 89.9166 52.4779 88.8736 51.8509 88.7386C43.8669 86.9996 35.8299 85.5866 29.4009 84.8666C28.8069 84.7996 28.6099 85.7766 29.2009 85.9166Z"
        fill="white"
      />
      <path
        d="M65.146 53.9357C65.546 54.4087 66.859 54.4897 66.046 53.2877C63.835 49.9997 58.801 43.3997 56.301 40.2997C55.869 39.7627 54.895 40.4437 55.301 41.0177C57.601 44.2617 61.501 49.6117 65.146 53.9357Z"
        fill="white"
      />
      <path
        d="M57.5639 72.4342C58.1309 72.7612 58.6189 71.8122 58.1089 71.4532C51.1089 66.5262 41.5629 60.3362 34.3809 56.2812C33.7619 55.9322 33.0809 56.7712 33.7019 57.1992C40.6929 62.0002 50.3199 68.2582 57.5639 72.4342Z"
        fill="white"
      />
      <path
        d="M99.7148 67.0814C99.6228 67.8964 99.0368 68.6024 98.3908 68.1174C98.1363 67.7803 97.9958 67.3709 97.9895 66.9486C97.9832 66.5263 98.1115 66.1129 98.3558 65.7684C99.0798 65.0704 99.8328 66.0354 99.7148 67.0814Z"
        fill="#282828"
      />
      <path
        d="M110.701 79.1674C108.768 80.7114 106.684 82.3334 104.206 82.6374C100.347 83.1114 98.3801 80.3614 97.1451 77.0814C95.0421 71.2734 94.549 65.0047 95.7181 58.9394C95.8421 58.2724 95.0731 58.0194 94.9241 58.6954C92.9191 67.7954 94.4981 84.3424 103.805 83.9004C103.516 85.3923 102.887 86.7975 101.966 88.0064C101.766 88.5974 102.207 88.8284 102.641 88.6414C103.788 88.1414 104.947 85.0934 105.085 83.7354C107.539 83.1224 109.752 81.785 111.435 79.8974C111.901 79.4004 111.207 78.7624 110.701 79.1674Z"
        fill="#282828"
      />
      <path
        d="M99.2567 60.9997C98.6771 61.0201 98.1104 61.1764 97.6023 61.4561C97.0942 61.7357 96.659 62.1309 96.3318 62.6097C96.0608 63.0387 96.4977 63.6817 97.1387 63.2097C97.5275 62.9095 97.9724 62.69 98.4473 62.5643C98.9221 62.4386 99.4173 62.4091 99.9037 62.4777C101.238 62.4447 100.754 60.9617 99.2567 60.9997Z"
        fill="#282828"
      />
      <path
        d="M103.448 62.6227C103.285 62.7054 103.157 62.8435 103.086 63.0123C103.016 63.181 103.008 63.3694 103.064 63.5435C103.12 63.7176 103.236 63.8661 103.391 63.9623C103.547 64.0586 103.731 64.0963 103.912 64.0687C104.53 63.8716 105.184 63.816 105.826 63.9058C106.468 63.9956 107.081 64.2287 107.621 64.5877C108.221 64.9467 108.399 64.3057 108.239 63.8977C107.651 62.3917 104.617 62.0997 103.448 62.6227Z"
        fill="#282828"
      />
      <path
        d="M106.862 66.8424C106.509 66.5234 106.063 66.3251 105.59 66.2764C105.117 66.2277 104.64 66.331 104.229 66.5714C103.75 66.8644 103.988 67.3164 104.497 67.2994C105.262 67.2851 106.018 67.461 106.697 67.8114C107.276 68.1204 107.672 67.5554 106.862 66.8424Z"
        fill="#282828"
      />
      <path
        d="M102.949 71.2683C102.432 71.3243 101.79 71.6553 101.301 71.2963C100.731 70.8803 101.601 69.0543 101.785 68.5383C102.092 67.6553 101.361 67.5993 101.015 68.1323C100.361 69.1413 99.3908 71.1653 100.543 72.1393C100.945 72.4093 101.42 72.5508 101.904 72.545C102.388 72.5393 102.859 72.3867 103.255 72.1073C103.78 71.7393 103.486 71.2103 102.949 71.2683Z"
        fill="#282828"
      />
      <path
        d="M107.966 72.6305C107.817 72.0205 107.347 71.7785 106.906 72.2885C106.278 73.1186 105.468 73.793 104.537 74.2597C103.607 74.7263 102.582 74.9726 101.541 74.9795C100.79 75.0405 100.849 75.7045 101.099 76.0735C103.026 78.9275 109.501 78.8705 107.966 72.6305ZM102.051 75.8625C103.011 75.9076 103.968 75.7286 104.847 75.3398C105.725 74.9511 106.502 74.3631 107.114 73.6225C107.459 77.0325 103.839 77.4545 102.051 75.8625Z"
        fill="#282828"
      />
      <path
        d="M88.8408 90.8396C85.7408 91.3116 77.9618 94.9216 75.2888 96.3506C70.1008 99.1246 60.7868 105.018 57.0008 109.6C56.5638 110.128 57.3348 110.7 57.7758 110.291C62.2695 106.169 67.2283 102.584 72.5518 99.6106C77.7065 96.4179 83.1969 93.8022 88.9238 91.8106C89.4608 91.6276 89.4448 90.7486 88.8408 90.8396Z"
        fill="#282828"
      />
      <path
        d="M153.953 107.04C152.412 103.573 148.004 99.0172 144.979 96.7122C142.479 94.8072 136.057 91.1683 132.728 90.7883C132.17 90.7243 131.712 91.4302 132.505 91.7972C136.337 93.5702 139.368 94.4373 144.162 97.9693C147.707 100.595 150.722 103.87 153.046 107.619C153.354 108.085 154.395 108.035 153.953 107.04Z"
        fill="#282828"
      />
      <path
        d="M188.801 97.7195C186.152 96.4035 183.422 99.4925 182.459 100.911C180.86 103.14 179.976 105.801 179.924 108.542C179.911 109.152 180.78 109.872 180.977 108.625C181.371 106.35 182.194 104.17 183.401 102.2C184.818 99.9165 185.91 98.9085 188.317 98.6565C189.283 98.5565 189.187 97.9095 188.801 97.7195Z"
        fill="#282828"
      />
      <path
        d="M191.172 100.235C188.055 99.4047 185.741 101.68 184.654 103.186C183.052 105.372 182.109 107.972 181.938 110.678C181.96 111.943 182.838 111.562 182.978 110.764C183.274 108.447 184.173 106.248 185.585 104.387C187.646 101.401 189.239 101.328 190.994 101.205C191.724 101.153 191.939 100.44 191.172 100.235Z"
        fill="#282828"
      />
      <path
        d="M191.722 102.64C189.679 102.406 187.708 104.175 186.668 105.495C185.51 106.965 184.746 108.706 184.448 110.553C184.183 112.272 184.999 112.358 185.273 111.573C185.797 109.845 186.608 108.218 187.673 106.76C189.432 104.592 190.543 104.015 191.96 103.91C193.485 103.8 192.501 102.729 191.722 102.64Z"
        fill="#282828"
      />
      <path
        d="M191.001 105.879C189.92 106.495 189.119 107.504 188.763 108.696C188.408 109.888 188.526 111.172 189.093 112.279C189.386 112.866 190.428 112.921 190.142 111.835C189.838 111.017 189.817 110.121 190.082 109.29C190.348 108.458 190.884 107.74 191.606 107.25C192.593 106.576 192.96 106.75 193.214 107.573C194.66 112.297 190.557 117.342 188.304 120.382C187.621 121.304 188.304 121.427 189.119 120.738C192.449 117.926 195.363 112.267 194.959 108.917C194.573 105.676 193.532 104.3 191.001 105.879Z"
        fill="#282828"
      />
      <path
        d="M14.7159 123.525C10.6759 120.078 7.89386 115.388 6.80592 110.19C5.73092 105 6.40692 101.8 11.5209 99.3715C14.0309 98.1785 15.7729 98.5715 18.0929 99.9665C22.3109 102.503 22.5809 107.067 21.9789 111.528C21.8569 112.428 22.8299 112.428 23.1239 111.354C24.4099 106.663 23.1759 101.222 18.7049 98.7645C16.2049 97.3905 13.5769 96.8435 10.9049 98.1155C5.49792 100.693 4.30492 104.859 5.33392 110.228C6.39183 115.864 9.52697 120.897 14.1189 124.333C14.5939 124.665 15.1369 123.905 14.7159 123.525Z"
        fill="#282828"
      />
      <path
        d="M19.837 106.308C19.1665 104.164 17.8545 102.278 16.078 100.903C15.623 100.567 14.931 101.221 15.365 101.642C18.506 104.693 19.583 109.584 19.965 113.916C20.053 114.916 20.838 114.389 20.925 113.729C21.1112 111.206 20.7397 108.672 19.837 106.308Z"
        fill="#282828"
      />
      <path
        d="M17.2139 108.389C16.1933 106.407 14.7483 104.676 12.9819 103.317C12.4279 102.889 11.8649 103.694 12.3379 104.157C13.8062 105.602 15.0328 107.273 15.9709 109.107C16.9552 110.906 17.5817 112.878 17.8159 114.916C17.9569 115.816 18.7369 115.437 18.8309 114.872C19.1909 112.709 17.9919 109.876 17.2139 108.389Z"
        fill="#282828"
      />
      <path
        d="M11.258 106.052C11.1576 105.965 11.0294 105.917 10.8966 105.916C10.7638 105.915 10.6351 105.962 10.5335 106.047C10.4319 106.133 10.3642 106.252 10.3425 106.383C10.3208 106.514 10.3465 106.648 10.415 106.762C11.26 108.162 12.496 109.304 12.786 110.992C13.07 112.648 12.468 113.306 11.286 114.272C10.842 114.634 10.955 115.272 11.597 115.232C13.462 115.117 14.465 112.87 14.313 111.257C13.9691 109.205 12.8816 107.352 11.258 106.052Z"
        fill="#282828"
      />
      <path
        d="M129.035 109.275C128.755 102.647 129.293 96.0094 130.635 89.5124C127.419 88.33 124.094 87.4695 120.708 86.9434C118.602 97.6514 111.602 102.59 104.315 101.828C96.1888 100.978 98.2978 91.5004 100.815 87.8444C98.2141 88.1085 95.6391 88.5824 93.1148 89.2614C89.6898 92.6464 84.1248 100.587 81.7388 110.224C87.4158 110.548 117.572 110.132 129.035 109.275Z"
        fill="#282828"
      />
      <path
        d="M117.407 73.9235C118.064 73.8144 118.687 73.5566 119.228 73.1696C119.77 72.7827 120.216 72.277 120.532 71.6911C120.848 71.1053 121.026 70.4548 121.052 69.7897C121.078 69.1245 120.951 68.4622 120.682 67.8535C119.52 65.4535 116.134 64.9935 114.724 67.1845C114.37 67.7345 115.157 68.1965 115.554 67.7215C115.833 67.402 116.188 67.1593 116.588 67.0165C116.987 66.8737 117.416 66.8356 117.834 66.9058C118.252 66.976 118.645 67.1522 118.975 67.4175C119.306 67.6829 119.563 68.0285 119.722 68.4215C120.615 70.5305 118.947 72.4955 116.887 72.8215C116.087 72.9495 116.231 73.5915 116.313 74.2105C116.969 79.1965 117.578 82.0375 117.482 88.1105C117.463 89.3035 118.34 88.7215 118.44 88.1515C118.894 85.5715 118.037 76.6755 117.407 73.9235Z"
        fill="#282828"
      />
    </svg>
  );
};

export default IconThankYou;
