export default function IconQuestionaryStep1() {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9295 6.42217L10.9295 5.48906C10.9295 4.90237 11.4092 4.42676 12.001 4.42676C12.5927 4.42676 13.0724 4.90237 13.0724 5.48906L13.0724 6.42217C14.8774 6.43836 16.6897 6.49544 18.5455 6.59343C20.266 6.68425 21.6777 8.0065 21.8203 9.72502C22.0613 12.6308 22.0613 15.2133 21.8203 18.1191C21.6777 19.8376 20.266 21.1598 18.5455 21.2506C14.099 21.4855 9.90295 21.4855 5.45642 21.2506C3.73598 21.1598 2.32423 19.8376 2.18171 18.1191C1.94073 15.2133 1.94073 12.6308 2.18171 9.72502C2.32423 8.0065 3.73598 6.68425 5.45642 6.59343C7.31228 6.49544 9.12452 6.43836 10.9295 6.42217ZM14.1885 10.656C14.1885 10.1671 14.5882 9.77077 15.0813 9.77077L18.0723 9.77077C18.5654 9.77077 18.9651 10.1671 18.9651 10.656C18.9651 11.1449 18.5654 11.5413 18.0723 11.5413L15.0813 11.5413C14.5882 11.5413 14.1885 11.1449 14.1885 10.656ZM15.0813 13.0367C14.5882 13.0367 14.1885 13.433 14.1885 13.9219C14.1885 14.4108 14.5882 14.8072 15.0813 14.8072L18.0723 14.8072C18.5654 14.8072 18.9651 14.4108 18.9651 13.9219C18.9651 13.433 18.5654 13.0367 18.0723 13.0367L15.0813 13.0367ZM9.10746 9.84943C8.32903 9.84943 7.67045 10.0676 7.20682 10.5273C6.74319 10.987 6.52316 11.6399 6.52316 12.4117C6.52316 13.1835 6.74319 13.8365 7.20682 14.2962C7.67045 14.7559 8.32903 14.974 9.10746 14.974C9.8859 14.974 10.5445 14.7559 11.0081 14.2962C11.4717 13.8365 11.6918 13.1835 11.6918 12.4117C11.6918 11.6399 11.4717 10.987 11.0081 10.5273C10.5445 10.0676 9.8859 9.84944 9.10746 9.84943ZM12.4045 17.4084C13.0035 18.0263 13.3972 18.8143 13.5622 19.692C10.9612 19.7271 8.3838 19.6746 5.7123 19.5345C5.36059 19.5161 5.03558 19.4035 4.7629 19.2222C4.97138 18.5127 5.33905 17.8773 5.85148 17.3642C6.66049 16.5541 7.79038 16.0894 9.10623 16.0894C10.4464 16.0894 11.5932 16.5715 12.4045 17.4084Z"
        fill="#282828"
      />
    </svg>
  );
}
