export default function IconQuestionaryStep1() {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14386 8.22511C4.14386 9.40553 4.51442 10.5002 5.12289 11.2745C5.56825 11.8413 6.09819 12.1855 6.62585 12.3267L6.80613 12.3267C6.44878 10.295 6.2381 8.18083 6.17236 6.11156C5.48519 6.34601 4.80782 6.62306 4.14383 6.94273L4.14386 8.22511ZM7.10743 14.4696C7.16283 14.4696 7.21726 14.4653 7.27039 14.4572C7.80798 16.082 9.04783 17.4051 10.693 17.6686C10.7733 17.6814 10.8533 17.6931 10.9329 17.7036C10.9271 17.7489 10.9241 17.7949 10.9241 17.8417L10.9241 20.6173C10.1897 20.6593 9.45185 20.7443 8.70369 20.8724C8.12045 20.9723 7.72859 21.526 7.82846 22.1093C7.92833 22.6926 8.4821 23.0844 9.06535 22.9846C11.051 22.6446 12.9401 22.6446 14.9258 22.9846C15.509 23.0844 16.0628 22.6926 16.1627 22.1093C16.2625 21.526 15.8707 20.9723 15.2874 20.8724C14.5393 20.7443 13.8014 20.6593 13.067 20.6173L13.067 17.8417C13.067 17.7954 13.0641 17.7497 13.0584 17.705C13.1415 17.6941 13.225 17.682 13.3088 17.6686C14.954 17.4051 16.1938 16.082 16.7314 14.4572C16.7845 14.4653 16.8391 14.4696 16.8945 14.4696L17.5033 14.4696C17.5801 14.4696 17.6568 14.4613 17.732 14.4448C18.8407 14.2025 19.8241 13.5401 20.564 12.5985C21.5024 11.4042 22.001 9.82882 22.001 8.22516L22.001 6.53793C22.001 5.99369 21.7028 5.47206 21.1934 5.21284C20.1073 4.66023 18.9864 4.21103 17.8435 3.86535C15.9369 3.28865 13.9693 3 12.0011 3C8.85313 3 5.70648 3.73831 2.8086 5.21284C2.29915 5.47206 2.00097 5.9937 2.00098 6.53793L2.001 8.22516C2.001 9.82881 2.4995 11.4042 3.43798 12.5985C4.17786 13.5401 5.16128 14.2025 6.26999 14.4448C6.34513 14.4613 6.42183 14.4696 6.49875 14.4696L7.10743 14.4696ZM17.3761 12.3267L17.1957 12.3267C17.553 10.295 17.7637 8.18079 17.8294 6.1115C18.5167 6.34597 19.1941 6.62304 19.8581 6.94273L19.8581 8.22512C19.8581 9.40554 19.4875 10.5002 18.8791 11.2745C18.4337 11.8413 17.9037 12.1855 17.3761 12.3267Z"
        fill="#282828"
      />
    </svg>
  );
}
