export const IconCasa = ({ locked = false }: { locked?: boolean }) => {
  return locked ? (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5827 4.98991C22.4218 4.34775 23.4464 4.00012 24.5 4.00012C25.5536 4.00012 26.5782 4.34775 27.4173 4.98991L29.5402 6.61425C30.6699 7.47847 31.7715 8.37919 32.8435 9.31501V7.79117C32.8435 6.93129 33.1824 6.10662 33.7856 5.49859C34.3888 4.89056 35.207 4.54897 36.06 4.54897H38.2697C39.1228 4.54897 39.9409 4.89056 40.5441 5.49859C41.1473 6.10662 41.4862 6.93129 41.4862 7.79117V18.458C42.8532 20.2056 44.1302 22.0245 45.317 23.9114L45.9989 24.9911C46.3077 25.4813 46.4802 26.046 46.4984 26.6264C46.5166 27.2067 46.3799 27.7814 46.1025 28.2902C45.8251 28.7991 45.4172 29.2236 44.9214 29.5192C44.4257 29.8149 43.8603 29.971 43.2842 29.9711H41.0616C41.1903 32.9539 41.0295 35.94 40.5792 38.8969C40.3617 40.318 39.6465 41.6136 38.5633 42.5491C37.48 43.4846 36.1002 43.9982 34.6737 43.9969H14.3263C12.9005 43.9968 11.5219 43.4826 10.4394 42.5472C9.35694 41.6119 8.6421 40.3171 8.42405 38.8969C7.97374 35.94 7.81292 32.9507 7.94158 29.9711H5.71578C5.13974 29.971 4.57431 29.8149 4.07856 29.5192C3.58281 29.2236 3.17493 28.7991 2.89753 28.2902C2.62013 27.7814 2.4834 27.2067 2.50161 26.6264C2.51982 26.046 2.69232 25.4813 3.00107 24.9911L3.67975 23.9114C7.87883 17.2467 13.2223 11.3895 19.4598 6.61425L21.5827 4.98991Z"
        fill="#9B9B9B"
      />
      <path
        d="M28.5197 43.9968H20.4785V33.3137C20.4785 32.2389 20.9021 31.2081 21.6561 30.448C22.4101 29.688 23.4328 29.261 24.4991 29.261C25.5654 29.261 26.5881 29.688 27.3421 30.448C28.0961 31.2081 28.5197 32.2389 28.5197 33.3137V44.0001V43.9968Z"
        fill="#D4D4D4"
      />
    </svg>
  ) : (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0827 4.98979C21.9218 4.34763 22.9464 4 24 4C25.0536 4 26.0782 4.34763 26.9173 4.98979L29.0402 6.61413C30.1699 7.47834 31.2715 8.37906 32.3435 9.31489V7.79105C32.3435 6.93117 32.6824 6.1065 33.2856 5.49847C33.8888 4.89044 34.707 4.54885 35.56 4.54885H37.7697C38.6228 4.54885 39.4409 4.89044 40.0441 5.49847C40.6473 6.1065 40.9862 6.93117 40.9862 7.79105V18.4579C42.3532 20.2055 43.6302 22.0243 44.817 23.9113L45.4989 24.991C45.8077 25.4811 45.9802 26.0459 45.9984 26.6263C46.0166 27.2066 45.8799 27.7812 45.6025 28.2901C45.3251 28.799 44.9172 29.2234 44.4214 29.5191C43.9257 29.8148 43.3603 29.9709 42.7842 29.971H40.5616C40.6903 32.9538 40.5295 35.9399 40.0792 38.8968C39.8617 40.3178 39.1465 41.6134 38.0633 42.549C36.98 43.4845 35.6002 43.9981 34.1737 43.9968H13.8263C12.4005 43.9966 11.0219 43.4825 9.9394 42.5471C8.85694 41.6118 8.1421 40.317 7.92405 38.8968C7.47374 35.9399 7.31292 32.9506 7.44158 29.971H5.21578C4.63974 29.9709 4.07431 29.8148 3.57856 29.5191C3.08281 29.2234 2.67493 28.799 2.39753 28.2901C2.12013 27.7812 1.9834 27.2066 2.00161 26.6263C2.01982 26.0459 2.19232 25.4811 2.50107 24.991L3.17975 23.9113C7.37883 17.2466 12.7223 11.3894 18.9598 6.61413L21.0827 4.98979Z"
        fill="#386CCC"
      />
      <path
        d="M28.0197 43.9966H19.9785V33.3135C19.9785 32.2386 20.4021 31.2078 21.1561 30.4478C21.9101 29.6877 22.9328 29.2607 23.9991 29.2607C25.0654 29.2607 26.0881 29.6877 26.8421 30.4478C27.5961 31.2078 28.0197 32.2386 28.0197 33.3135V43.9998V43.9966Z"
        fill="#80ABF9"
      />
    </svg>
  );
};
