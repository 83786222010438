interface Props {
  color: string;
  size?: number;
}

export default function FilledStar({ color, size = 20 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8296 1.72236C11.8421 -0.653287 8.59069 -0.51199 7.64354 1.77981C7.20723 2.8341 6.83613 3.89771 6.44174 5.19112C4.98219 5.17093 3.78195 5.20354 2.57549 5.31534C0.106679 5.54358 -0.941401 8.59466 1.02899 10.2141C1.95993 10.9702 2.94032 11.6632 3.96361 12.2886V12.2979C3.48836 13.6758 3.10453 15.0835 2.81461 16.5119C2.33016 18.9466 4.95735 20.8471 7.09543 19.4559C8.18172 18.7416 9.21067 17.9436 10.1729 17.0694C11.2753 18.0817 12.2427 18.8503 13.3001 19.5351C15.4537 20.9278 18.0079 18.9714 17.5312 16.5771C17.2372 15.1272 16.8566 13.6961 16.3915 12.2917C17.3821 11.6814 18.3332 11.0093 19.2392 10.2794C21.2484 8.64435 20.1506 5.56532 17.6709 5.34173C16.4353 5.23594 15.1954 5.18827 13.9553 5.19888C13.6683 4.01298 13.2925 2.85034 12.8311 1.72081L12.8296 1.72236Z"
        fill={color}
      />
    </svg>
  );
}
