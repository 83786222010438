import './App.css';
import AppRoutes from './Routes';
import { useDayjs } from '@laborability/commons';

function App() {
  useDayjs();

  return <AppRoutes />;
}

export default App;
