export const IconSalute = ({ locked = false }: { locked?: boolean }) => {
  return locked ? (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8628_21885)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.81532 1.99991C4.21038 1.99988 3.62619 2.22171 3.17214 2.62386C2.7181 3.02601 2.42535 3.5809 2.34872 4.1846V4.19127L2.34541 4.20794L2.33877 4.26798L2.30894 4.49145L2.21279 5.3253C2.13323 6.03908 2.02714 7.04971 1.92436 8.23711C1.66378 11.0326 1.5222 13.838 1.5 16.6457C1.5 23.0129 6.67521 27.986 12.4803 29.1167V30.1807C12.4803 34.111 14.0322 37.8803 16.7946 40.6595C19.557 43.4386 23.3036 44.9999 27.2103 44.9999C31.1169 44.9999 34.8635 43.4386 37.6259 40.6595C40.3883 37.8803 41.9402 34.111 41.9402 30.1807V20.4513C41.9402 19.7879 41.6782 19.1516 41.2119 18.6825C40.7456 18.2134 40.1132 17.9498 39.4537 17.9498C38.7943 17.9498 38.1618 18.2134 37.6955 18.6825C37.2292 19.1516 36.9672 19.7879 36.9672 20.4513V30.1807C36.9672 32.7841 35.9393 35.2809 34.1095 37.1217C32.2797 38.9626 29.798 39.9968 27.2103 39.9968C24.6226 39.9968 22.1408 38.9626 20.311 37.1217C18.4813 35.2809 17.4533 32.7841 17.4533 30.1807V29.1167C23.2551 27.9827 28.4303 23.0129 28.4303 16.6457C28.4303 13.7205 28.2181 10.5986 28.0059 8.23711C27.8955 6.98678 27.7673 5.7381 27.6214 4.49145L27.5915 4.26798L27.5849 4.20794V4.19127V4.1846L25.115 4.50146L27.5816 4.1846C27.505 3.5809 27.2122 3.02601 26.7582 2.62386C26.3041 2.22171 25.7199 1.99988 25.115 1.99991H22.2937C21.6342 1.99991 21.0018 2.26346 20.5354 2.7326C20.0691 3.20173 19.8072 3.83801 19.8072 4.50146C19.8072 5.16491 20.0691 5.80119 20.5354 6.27032C21.0018 6.73946 21.6342 7.00301 22.2937 7.00301H22.8937C22.9435 7.50332 22.9998 8.067 23.0529 8.68072C23.2584 10.9821 23.4573 13.934 23.4573 16.6457C23.4573 20.6615 19.4557 24.3571 14.9668 24.3571C10.4746 24.3571 6.47297 20.6615 6.47297 16.6457C6.47297 13.934 6.67189 10.9788 6.87744 8.68072C6.93049 8.067 6.98685 7.49999 7.03658 7.00301H7.49077C8.15023 7.00301 8.78268 6.73946 9.24899 6.27032C9.71529 5.80119 9.97726 5.16491 9.97726 4.50146C9.97726 3.83801 9.71529 3.20173 9.24899 2.7326C8.78268 2.26346 8.15023 1.99991 7.49077 1.99991H4.81532Z"
          fill="#9B9B9B"
        />
        <path
          d="M39.4378 22.1758C44.5965 22.1758 47.5007 19.2573 47.5007 14.0641C47.5007 8.8709 44.5965 5.95242 39.4378 5.95242C34.2759 5.95242 31.375 8.8709 31.375 14.0641C31.375 19.2573 34.2759 22.1758 39.4378 22.1758Z"
          fill="#D4D4D4"
        />
      </g>
      <defs>
        <clipPath id="clip0_8628_21885">
          <rect
            width="46"
            height="46"
            fill="white"
            transform="translate(1.5 1)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3241_1011)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.31532 2C3.71038 1.99997 3.12619 2.2218 2.67214 2.62395C2.2181 3.02611 1.92535 3.58099 1.84872 4.18469V4.19136L1.84541 4.20804L1.83877 4.26807L1.80894 4.49154L1.71279 5.3254C1.63323 6.03917 1.52714 7.0498 1.42436 8.2372C1.16378 11.0327 1.0222 13.8381 1 16.6457C1 23.013 6.17521 27.9861 11.9803 29.1168V30.1808C11.9803 34.1111 13.5322 37.8804 16.2946 40.6596C19.057 43.4387 22.8036 45 26.7103 45C30.6169 45 34.3635 43.4387 37.1259 40.6596C39.8883 37.8804 41.4402 34.1111 41.4402 30.1808V20.4514C41.4402 19.788 41.1782 19.1517 40.7119 18.6826C40.2456 18.2134 39.6132 17.9499 38.9537 17.9499C38.2943 17.9499 37.6618 18.2134 37.1955 18.6826C36.7292 19.1517 36.4672 19.788 36.4672 20.4514V30.1808C36.4672 32.7842 35.4393 35.281 33.6095 37.1218C31.7797 38.9627 29.298 39.9969 26.7103 39.9969C24.1226 39.9969 21.6408 38.9627 19.811 37.1218C17.9813 35.281 16.9533 32.7842 16.9533 30.1808V29.1168C22.7551 27.9828 27.9303 23.013 27.9303 16.6457C27.9303 13.7206 27.7181 10.5987 27.5059 8.2372C27.3955 6.98687 27.2673 5.73819 27.1214 4.49154L27.0915 4.26807L27.0849 4.20804V4.19136V4.18469L24.615 4.50155L27.0816 4.18469C27.005 3.58099 26.7122 3.02611 26.2582 2.62395C25.8041 2.2218 25.2199 1.99997 24.615 2H21.7937C21.1342 2 20.5018 2.26356 20.0354 2.73269C19.5691 3.20182 19.3072 3.8381 19.3072 4.50155C19.3072 5.165 19.5691 5.80128 20.0354 6.27042C20.5018 6.73955 21.1342 7.0031 21.7937 7.0031H22.3937C22.4435 7.50341 22.4998 8.0671 22.5529 8.68081C22.7584 10.9822 22.9573 13.9341 22.9573 16.6457C22.9573 20.6616 18.9557 24.3572 14.4668 24.3572C9.97456 24.3572 5.97297 20.6616 5.97297 16.6457C5.97297 13.9341 6.17189 10.9789 6.37744 8.68081C6.43049 8.0671 6.48685 7.50008 6.53658 7.0031H6.99077C7.65023 7.0031 8.28268 6.73955 8.74899 6.27042C9.21529 5.80128 9.47726 5.165 9.47726 4.50155C9.47726 3.8381 9.21529 3.20182 8.74899 2.73269C8.28268 2.26356 7.65023 2 6.99077 2H4.31532Z"
          fill="#386CCC"
        />
        <path
          d="M38.9378 22.176C44.0965 22.176 47.0007 19.2576 47.0007 14.0643C47.0007 8.87111 44.0965 5.95264 38.9378 5.95264C33.7759 5.95264 30.875 8.87111 30.875 14.0643C30.875 19.2576 33.7759 22.176 38.9378 22.176Z"
          fill="#9AE6C8"
        />
      </g>
      <defs>
        <clipPath id="clip0_3241_1011">
          <rect
            width="46"
            height="46"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
