import * as React from 'react';
import styled from 'styled-components';

export type StyledListProps = {
  marginTop?: string;

  children: React.ReactNode;
};

const StyledList = styled.ul<{ marginTop?: string }>`
  margin-top: ${props => props.marginTop};
`;

const AnswerBoxList: React.FC<StyledListProps> = ({
  marginTop = '0px',

  children,
}: StyledListProps) => {
  return <StyledList marginTop={marginTop}>{children}</StyledList>;
};

export default AnswerBoxList;
