import {
  LBTDivider,
  LBTDividerSize,
  LBTLabel,
  LBTSpacer,
} from '@laborability/components';
import Box, { BoxProps } from '@mui/material/Box';
import List, { ListProps } from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import React from 'react';
import styled from 'styled-components';

export type LBTListProps = ListProps & {
  datatestid?: string;
};

export type LBTListItemProps = ListItemProps & {
  datatestid?: string;
};

const StyledList = styled(List)<ListProps & {}>(({ ...props }) => ({}));

const StyledListItem = styled(ListItem)<ListItemProps & {}>(({ ...props }) => ({
  padding: '16px 4px',
  minHeight: '57px',
}));

const CustomBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const AssocaafComunicazioneEnea: React.FC<LBTListProps> = ({
  datatestid = '',
  ...props
}: LBTListProps) => {
  return (
    <>
      <LBTLabel variant="smallCapsBold" component="h5" textAlign="left">
        costi
      </LBTLabel>
      <LBTSpacer spacing={2} isFixed />
      <StyledList disablePadding {...props}>
        <LBTDivider
          size={LBTDividerSize.MEDIUM}
          orientation="horizontal"
          width="100%"
        />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel textAlign="left" variant="listTitle">
              Ecobonus
            </LBTLabel>
            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              140€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel textAlign="left" variant="listTitle">
              Bonus casa
            </LBTLabel>

            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              90€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel textAlign="left" variant="listTitle">
              Visura catastale (ad immobile)
            </LBTLabel>
            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              10€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel textAlign="left" variant="listTitle">
              Predisposizione modello F24
            </LBTLabel>
            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              25€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
      </StyledList>
    </>
  );
};

export default AssocaafComunicazioneEnea;
