import { Benefit, Misura } from '@laborability/commons';
import {
  COLORS,
  IconCalendarComponent,
  IconEnteComponent,
  IconEuroComponent,
  IconInboxComponent,
  IconLocationComponent,
  IconsSize,
  IconsStyle,
  LBTListItem,
} from '@laborability/components';
import { List } from '@mui/material';
import dayjs from 'dayjs';

const BonusList: React.FC<{
  misura?: Misura;
  beneficio?: Benefit;
  isDesktop: boolean;
}> = ({ misura, beneficio, isDesktop }) => {
  const startDate = dayjs(misura?.start_date, 'DD-MM-YYYY HH:mm:SS').format(
    'DD/MM/YYYY',
  );
  const endDate = dayjs(misura?.end_date, 'DD-MM-YYYY HH:mm:SS').format(
    'DD/MM/YYYY',
  );

  const renderAviableZone = (): string => {
    if (
      (misura?.regions && misura?.regions?.length >= 2) ||
      misura?.province?.length === 0
    )
      return misura?.regions?.map(el => el.name)?.join(', ') ?? '-';
    return `${misura?.regions?.map(el => el.name)?.join(', ')}, in provincia di ${misura?.province?.map(el => el.name)?.join(', ') ?? '-'}`;
  };

  return (
    <List
      disablePadding
      sx={{
        order: isDesktop ? 1 : 3,
        marginTop: isDesktop ? 0 : '16px',
      }}
    >
      <LBTListItem
        title={beneficio?.name ?? ''}
        description={beneficio?.description ?? ''}
        alignItems="flex-start"
        leftItem={
          <IconInboxComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        }
      />
      {misura?.final_range && misura?.initial_range && (
        <LBTListItem
          title={`${misura?.initial_range}-${misura?.final_range}€`}
          description="Riceverai una somma compresa in questo intervallo"
          alignItems="flex-start"
          leftItem={
            <IconEuroComponent
              size={IconsSize.LARGE}
              style={IconsStyle.FILLED}
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            />
          }
        />
      )}
      <LBTListItem
        title={misura?.national ? 'Nazionale' : 'Regionale'}
        description={
          misura?.national
            ? 'Richiedi questo bonus in tutto il territorio italiano'
            : `Questo bonus è disponibile in ${renderAviableZone()}`
        }
        alignItems="flex-start"
        leftItem={
          <IconLocationComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        }
      />
      <LBTListItem
        title={`Dal ${startDate} al ${endDate}`}
        description="Il bonus è valido in questo periodo"
        alignItems="flex-start"
        leftItem={
          <IconCalendarComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        }
      />
      <LBTListItem
        title={misura?.agency?.name ?? ''}
        description={`Il bonus è gestito da un ente ${misura?.agency?.is_public_agency ? 'pubblico' : 'privato'}`}
        alignItems="flex-start"
        leftItem={
          <IconEnteComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        }
      />
    </List>
  );
};

export default BonusList;
