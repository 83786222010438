import { Misura } from '@laborability/commons';
import { LBTAlert } from '@laborability/components';
import { Box } from '@mui/material';

const AlertsList: React.FC<{ misura?: Misura; isDesktop: boolean }> = ({
  misura,
  isDesktop,
}) => {
  return (
    <Box>
      {misura?.for_all_incomes && (
        <LBTAlert
          title="Per tutti i redditi"
          color="all_incomes"
          variant="standard"
          message="Ottieni questo bonus senza presentare l'ISEE"
          datatestid="alert-1"
          sx={{ order: isDesktop ? 3 : 1 }}
        />
      )}
      {misura?.question_online_or_phone && (
        <LBTAlert
          title="Domanda online o al telefono"
          color="online_or_phone"
          variant="standard"
          message="Ottieni questo bonus senza dover andare allo sportello"
          datatestid="alert-1"
          sx={{ order: isDesktop ? 4 : 2, marginTop: '16px' }}
        />
      )}
    </Box>
  );
};

export default AlertsList;
