import { LBTLabel, LBTSpacer } from '@laborability/components';
import BoldSpan from './BoldSpan';
import AnswerBoxList from './StyledList';

const AnswerBoxOne: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      Il lavoratore deve, dopo aver{' '}
      <BoldSpan>scelto l'istituto finanziario</BoldSpan> erogatore del prestito,{' '}
      <BoldSpan>dimostrare</BoldSpan> alla banca o alla società finanziaria di{' '}
      <BoldSpan>essere in possesso</BoldSpan> dei requisiti{' '}
      <BoldSpan>per pagare</BoldSpan> il suo <BoldSpan>debito</BoldSpan>{' '}
      attraverso la <BoldSpan>cessione del quinto.</BoldSpan>
      <LBTSpacer spacing={2} isFixed />
      Una volta che l'
      <BoldSpan>istituto finanziario</BoldSpan> avrà accertato i requisiti{' '}
      <BoldSpan>stipulerà</BoldSpan> con il lavoratore un{' '}
      <BoldSpan>contratto</BoldSpan> con cui quest'ultimo cederà parte dei suoi
      futuri stipendi.
    </LBTLabel>
  );
};

const AnswerBoxTwo: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      I <BoldSpan>documenti</BoldSpan> solitamente{' '}
      <BoldSpan>richiesti dall'Istituto Finanziario</BoldSpan> per la
      concessione di un prestito contro cessione dello stipendio sono:
      <AnswerBoxList marginTop="28px">
        <li>
          copia del <BoldSpan>documento di identità e codice fiscale;</BoldSpan>
        </li>
        <li>
          l'
          <BoldSpan>ultima busta paga;</BoldSpan>
        </li>
        <li>
          l'ultima <BoldSpan>Certificazione Unica;</BoldSpan>
        </li>
        <li>
          un <BoldSpan>certificato di stipendio.</BoldSpan>
        </li>
      </AnswerBoxList>
      <LBTSpacer spacing={2} isFixed />
      Cosa bisogna presentare al datore di lavoro:
      <AnswerBoxList marginTop="28px">
        <li>
          <BoldSpan>documento</BoldSpan> che attesti il{' '}
          <BoldSpan>finanziamento;</BoldSpan>
        </li>
      </AnswerBoxList>
    </LBTLabel>
  );
};

const AnswerBoxThree: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      Il <BoldSpan>lavoratore</BoldSpan> che intenda chiedere un prestito ad una
      banca o ad una finanziaria può <BoldSpan>pagare</BoldSpan> il suo{' '}
      <BoldSpan>debito</BoldSpan>, attraverso delle{' '}
      <BoldSpan>trattenute</BoldSpan> operate dal proprio{' '}
      <BoldSpan>datore di lavoro sullo stipendio</BoldSpan>, se titolare di uno{' '}
      <BoldSpan>stipendio fisso</BoldSpan>. Il lavoratore deve comunicare alla
      società finanziaria di voler pagare il proprio debito attraverso il suo
      datore di lavoro che, al momento del pagamento dello stipendio, verserà
      parte dello stesso direttamente alla società finanziaria.
    </LBTLabel>
  );
};

export { AnswerBoxOne, AnswerBoxThree, AnswerBoxTwo };
