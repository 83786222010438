export default function IconQuestionaryStep1() {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.001 9.48343C21.9669 7.16511 20.638 4.88197 18.598 3.94116C17.5016 3.43415 16.2507 3.36127 15.1017 3.73746C14.0573 4.06572 13.0099 4.73835 12.001 5.7964C10.9921 4.73835 9.94468 4.06718 8.90024 3.73746C7.75126 3.36127 6.50033 3.43415 5.40394 3.94116C3.36394 4.88197 2.03505 7.16511 2.00098 9.48343V9.49516C2.00098 12.8759 4.03357 15.8713 6.18764 17.9742C7.16568 18.9353 8.25062 19.7839 9.42172 20.5035C9.92246 20.8039 10.3951 21.0457 10.8143 21.2157C11.2158 21.3769 11.6336 21.5 12.001 21.5C12.3684 21.5 12.7862 21.3769 13.1862 21.2157C13.6069 21.0472 14.0795 20.8054 14.5788 20.5035C15.7504 19.7839 16.8358 18.9354 17.8143 17.9742C19.9684 15.8713 22.001 12.8759 22.001 9.49662V9.4849V9.48343Z"
        fill="#282828"
      />
    </svg>
  );
}
