export default function IconQuestionaryStep1() {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.59929 9.24979C1.80182 9.77224 1.80229 10.5213 2.59618 11.0452C2.87751 11.2309 3.15958 11.412 3.44257 11.5888L3.44227 11.612L3.44227 17.8505C2.69924 18.1869 2.18286 18.9301 2.18286 19.7928C2.18286 20.9714 3.14648 21.9268 4.33516 21.9268C5.52384 21.9268 6.48746 20.9714 6.48746 19.7928C6.48746 18.9301 5.97106 18.1869 5.22799 17.8505L5.22799 12.6422C6.8653 13.5539 8.54474 14.3463 10.2999 15.0734C11.3019 15.4885 12.7343 15.4888 13.7335 15.0718C16.4214 13.95 18.7438 12.7617 21.4027 11.0616C22.2001 10.5392 22.1997 9.79016 21.4058 9.2662C18.9358 7.63608 16.409 6.35938 13.7021 5.23798C12.7 4.82287 11.2676 4.82262 10.2685 5.23961C7.58051 6.36139 5.05926 7.63813 2.59929 9.24979ZM6.43707 15.9289C6.41476 15.7043 6.39419 15.4767 6.37667 15.2464C7.43483 15.7684 8.51086 16.2511 9.61145 16.7071C11.0531 17.3043 12.9832 17.3059 14.4263 16.7037C15.5357 16.2406 16.5871 15.7655 17.6252 15.2498C17.6077 15.4789 17.5872 15.7054 17.5651 15.9289C17.3928 17.6624 15.9856 19.0584 14.2464 19.232C13.5248 19.3041 12.7725 19.3609 12.0011 19.3609C11.2296 19.3609 10.4773 19.3041 9.75575 19.232C8.01653 19.0584 6.60929 17.6624 6.43707 15.9289Z"
        fill="#282828"
      />
    </svg>
  );
}
