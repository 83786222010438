import IconQuestionaryStep1 from './IconQuestionaryStep1';
import IconQuestionaryStep2 from './IconQuestionaryStep2';
import IconQuestionaryStep3 from './IconQuestionaryStep3';
import IconQuestionaryStep4 from './IconQuestionaryStep4';
import IconQuestionaryStep5 from './IconQuestionaryStep5';
import IconQuestionaryStep6 from './IconQuestionaryStep6';
import IconQuestionaryStep7 from './IconQuestionaryStep7';
import IconQuestionaryStep8 from './IconQuestionaryStep8';
import IconQuestionaryStep9 from './IconQuestionaryStep9';

interface Props {
  step: number;
}

export default function IconQuestionaryStep({ step }: Props) {
  if (step === 1) return <IconQuestionaryStep1 />;
  if (step === 2) return <IconQuestionaryStep2 />;
  if (step === 3) return <IconQuestionaryStep3 />;
  if (step === 4) return <IconQuestionaryStep4 />;
  if (step === 5) return <IconQuestionaryStep5 />;
  if (step === 6) return <IconQuestionaryStep6 />;
  if (step === 7) return <IconQuestionaryStep7 />;
  if (step === 8) return <IconQuestionaryStep8 />;
  if (step === 9) return <IconQuestionaryStep9 />;
  return null;
}
