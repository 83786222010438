import { ReactNode } from 'react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  onClick: () => void;
}

export default function UnstyledButton({
  children,
  onClick,
  style,
  ...props
}: Props) {
  return (
    <button
      style={{
        padding: 0,
        border: 0,
        paddingBlock: 0,
        paddingInline: 0,
        cursor: 'pointer',
        backgroundColor: 'transparent',
        ...style,
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}
