import {
  LBTLabel,
  COLORS,
  LBTSpacer,
  Section,
  LBTButton,
  LBTAlert,
} from '@laborability/components';
import { ReactComponent as AstronautImg } from '../../assets/images/astronaut.svg';
import { useTrackAnalytics } from '@laborability/commons';

export default function QuestionaryCompletedLoader() {
  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    {
      key: 'page_title',
      value: 'Questionario - Hai sbloccato tutte le agevolazioni',
    },
    { key: 'page_location', value: '/onboarding/tutte-le-agevolazioni/' },
  ]);

  return (
    <Section backgroundColor={COLORS.getInstance().PRIMARY_MAIN}>
      <LBTSpacer spacing={2} isFixed />
      <AstronautImg />
      <LBTLabel variant="sourceSubtitle2" component="p">
        Incredibile ma vero
      </LBTLabel>
      <LBTSpacer spacing={1} />
      <LBTLabel variant="delaDisplay" component="h1">
        Hai sbloccato tutte le agevolazioni
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Grazie alle risposte che hai dato abbiamo selezionato le agevolazioni
        che ti spettano
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <LBTButton
        fullWidth
        size="large"
        variant="contained"
        color="primary-negative"
        onClick={() => {}}
        datatestid="onboarding_button_sbloccato_tutte_le_agevolazioni"
      >
        Vai alle tue agevolazioni
      </LBTButton>
      <LBTSpacer spacing={4} />
      <LBTAlert
        onClose={() => {}}
        variant="standard"
        title="Ricorda!"
        message="Mantieni sempre aggiornate nel tempo le informazioni che riguardano te e i tuoi cari: le regole di accesso possono cambiare e ogni anno vengono pubblicati nuovi bonus. Puoi modificare le risposte dalla sezione Questionario raggiungibile dal menu in alto a destra"
        datatestid={''}
      ></LBTAlert>
      <LBTSpacer spacing={8} isFixed />
    </Section>
  );
}
