import { FONTS } from '../../utils';

interface Props {
  label: string;
}

export default function Avatar({ label }: Props) {
  return (
    <div style={{ position: 'relative', height: 44 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
      >
        <clipPath id="clip">
          <circle cx="22" cy="22" r="21.5" />
        </clipPath>
        <mask id="mask">
          <rect x="0" y="0" width="100" height="100" fill="white" />
          <circle cx="22" cy="22" r="18.5" fill="black" />
        </mask>
        <foreignObject
          x="0"
          y="0"
          width="44"
          height="44"
          mask="url(#mask)"
          clipPath="url(#clip)"
        >
          <div
            className="gradient"
            style={{
              width: '100%',
              height: '100%',
              backgroundImage:
                'conic-gradient(#ffa084,#f7c4fb,#f7c4fb,#9ae6c8,#9ae6c8,#fffa74,#fffa74,#ffa084)',
            }}
          />
        </foreignObject>
      </svg>
      <div
        style={{
          fontFamily: FONTS.DELA_GOTHIC_ONE,
          position: 'absolute',
          width: '100%',
          height: '100%',
          fontSize: '20px',
          inset: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {label}
      </div>
    </div>
  );
}
