export default function IconStar() {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8306 4.22236C13.843 1.84671 10.5917 1.98801 9.64452 4.27981C9.2082 5.3341 8.83711 6.39771 8.44272 7.69112C6.98317 7.67093 5.78292 7.70354 4.57647 7.81534C2.10766 8.04358 1.05958 11.0947 3.02997 12.7141C3.96091 13.4702 4.9413 14.1632 5.96459 14.7886V14.7979C5.48934 16.1758 5.1055 17.5835 4.81558 19.0119C4.33114 21.4466 6.95832 23.3471 9.09641 21.9559C10.1827 21.2416 11.2116 20.4436 12.1739 19.5694C13.2763 20.5817 14.2436 21.3503 15.301 22.0351C17.4547 23.4278 20.0089 21.4714 19.5322 19.0771C19.2382 17.6272 18.8576 16.1961 18.3925 14.7917C19.383 14.1814 20.3341 13.5093 21.2402 12.7794C23.2494 11.1443 22.1516 8.06532 19.6719 7.84173C18.4363 7.73594 17.1964 7.68827 15.9563 7.69888C15.6693 6.51298 15.2935 5.35034 14.8321 4.22081L14.8306 4.22236Z"
        fill="#282828"
      />
    </svg>
  );
}
