import { IconsSize, IconsStyle } from '../../enums';
import StyledIcon from './StyledIcon';

const IconSearchComponent = ({
  datatestid = '',
  size,
  style,
  color,
}: {
  datatestid?: string;
  size: IconsSize;
  style: IconsStyle;
  color: string;
}) => {
  return style === IconsStyle.OUTLINE ? (
    <StyledIcon
      datatestid={`lbt-icon-${datatestid}`}
      data-track={datatestid}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7413 3.00647C10.6936 3.01032 10.5374 3.02276 10.3943 3.03411C8.77787 3.16215 7.36683 3.61848 6.21381 4.38606C4.48178 5.53908 3.40402 7.42011 3.08833 9.84102C3.00803 10.4568 2.99938 10.6192 3.00003 11.4964C3.00071 12.3726 3.01305 12.5879 3.09799 13.2025C3.31758 14.7915 3.86445 16.1358 4.73702 17.2314C5.07028 17.6498 5.61658 18.1586 6.09922 18.4999C7.24653 19.3114 8.80446 19.8184 10.5196 19.9386C11.9501 20.0389 13.2954 19.9214 14.4918 19.5918C15.5735 19.2938 16.5879 18.7744 17.3243 18.1417L17.4573 18.0275L18.9151 19.483C19.7169 20.2836 20.4007 20.9524 20.4347 20.9693C20.5658 21.0347 20.7972 20.991 20.8975 20.8819C20.9967 20.774 21.0309 20.5597 20.9689 20.4353C20.952 20.4013 20.2829 19.7177 19.4822 18.9161L18.0262 17.4587L18.1405 17.3258C18.7734 16.5896 19.2929 15.5755 19.591 14.494C19.8137 13.6861 19.9161 12.9905 19.9608 11.9816C20.0095 10.8842 19.9054 9.74264 19.6678 8.76554C19.3151 7.31569 18.6861 6.15451 17.7421 5.21085C16.9452 4.41416 16.0152 3.8575 14.8434 3.47579C14.2008 3.26648 13.5412 3.1337 12.6889 3.04205C12.4268 3.01387 10.9776 2.9874 10.7413 3.00647ZM10.7413 3.8164C10.1295 3.86648 9.89024 3.89457 9.5169 3.96015C8.18974 4.19327 7.04665 4.69436 6.16173 5.43098C5.98418 5.57877 5.6247 5.93567 5.46961 6.11811C4.61967 7.11802 4.09517 8.39049 3.88838 9.95427C3.81904 10.4786 3.80926 10.6657 3.80926 11.4675C3.80926 12.3206 3.82158 12.5295 3.90755 13.1338C4.33799 16.1596 6.03807 18.1358 8.82635 18.8517C10.3674 19.2474 12.3383 19.2703 13.894 18.9106C16.2782 18.3593 17.8996 16.9507 18.6558 14.7736C19.1197 13.4379 19.2749 11.7818 19.0898 10.1422C18.789 7.4781 17.5509 5.57565 15.4808 4.59678C14.647 4.20254 13.7768 3.97277 12.66 3.85202C12.3882 3.82262 10.9836 3.79654 10.7413 3.8164Z"
        fill={color}
      />
    </StyledIcon>
  ) : (
    <StyledIcon
      datatestid={`lbt-icon-${datatestid}`}
      data-track={datatestid}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.86477 10.5972C4.86477 8.56892 5.43057 7.18524 6.30862 6.30862C7.18524 5.43057 8.56892 4.86477 10.5972 4.86477C12.6269 4.86477 14.0091 5.43057 14.8872 6.30862C15.7638 7.18524 16.3296 8.56892 16.3296 10.5972C16.3296 12.6269 15.7638 14.0091 14.8872 14.8872C14.0091 15.7638 12.6269 16.3296 10.5972 16.3296C8.56892 16.3296 7.18524 15.7638 6.30862 14.8872C5.43057 14.0091 4.86477 12.6269 4.86477 10.5972ZM10.5972 2C8.04037 2 5.84166 2.72336 4.28179 4.28179C2.72336 5.84166 2 8.04037 2 10.5986C2 13.154 2.72336 15.3541 4.28179 16.9126C5.84166 18.471 8.04037 19.1944 10.5986 19.1944C12.5925 19.1944 14.3687 18.7546 15.7939 17.8193L19.5725 21.5979C19.8427 21.8588 20.2045 22.0032 20.5801 21.9999C20.9556 21.9967 21.3149 21.846 21.5805 21.5805C21.846 21.3149 21.9967 20.9556 21.9999 20.5801C22.0032 20.2045 21.8588 19.8427 21.5979 19.5725L17.8207 15.7953C18.7546 14.3687 19.1958 12.5925 19.1958 10.5972C19.1958 8.04037 18.471 5.84166 16.9126 4.28179C15.3527 2.72336 13.154 2 10.5972 2Z"
        fill={color}
      />
    </StyledIcon>
  );
};

export default IconSearchComponent;
