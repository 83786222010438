import {
  LBTDivider,
  LBTDividerSize,
  LBTLabel,
  LBTSpacer,
} from '@laborability/components';
import Box, { BoxProps } from '@mui/material/Box';
import List, { ListProps } from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import React from 'react';
import styled from 'styled-components';

export type LBTListProps = ListProps & {
  datatestid?: string;
};

export type LBTListItemProps = ListItemProps & {
  datatestid?: string;
};

const StyledList = styled(List)<ListProps & {}>(({ ...props }) => ({}));

const StyledListItem = styled(ListItem)<ListItemProps & {}>(({ ...props }) => ({
  padding: '16px 4px',
  minHeight: '57px',
}));

const CustomBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const AssocaafModello730: React.FC<LBTListProps> = ({
  datatestid = '',
  ...props
}: LBTListProps) => {
  return (
    <>
      <LBTLabel variant="smallCapsBold" component="h5" textAlign="left">
        costi
      </LBTLabel>
      <LBTSpacer spacing={2} isFixed />
      <StyledList disablePadding {...props}>
        <LBTDivider
          size={LBTDividerSize.MEDIUM}
          orientation="horizontal"
          width="100%"
        />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel textAlign="left" variant="listTitle">
              Singolo
            </LBTLabel>

            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              55€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <StyledListItem disablePadding>
          <CustomBox width="100%">
            <LBTLabel
              textAlign="left"
              variant="listTitle"
              sx={{ maxWidth: '80%' }}
            >
              Congiunto
            </LBTLabel>
            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              100€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <LBTSpacer spacing={3} isFixed />
      </StyledList>

      <LBTLabel textAlign="left" variant="smallCapsBold" component="h5">
        costi aggiuntivi
      </LBTLabel>
      <LBTSpacer spacing={2} isFixed />
      <StyledList disablePadding {...props}>
        <LBTDivider
          size={LBTDividerSize.MEDIUM}
          orientation="horizontal"
          width="100%"
        />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel textAlign="left" variant="listTitle">
              Inserimento dati da dichiarazione di successione
            </LBTLabel>

            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              25€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel textAlign="left" variant="listTitle">
              Inserimento di oneri per bonus casa su interventi individuali
              oltre 7.500 €
            </LBTLabel>
            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              25€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <LBTSpacer spacing={3} isFixed />
      </StyledList>

      <LBTLabel textAlign="left" variant="smallCapsBold" component="h5">
        casi particolari sottoposti a preventivo
      </LBTLabel>
      <LBTSpacer spacing={2} isFixed />
      <StyledList disablePadding {...props}>
        <LBTDivider
          size={LBTDividerSize.MEDIUM}
          orientation="horizontal"
          width="100%"
        />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel textAlign="left" variant="listTitle">
              Superbonus, crediti d'imposta esteri, agevolazioni per impatriati,
              riacquisto/acquisto abitazione principale (costo aggiuntivo solo
              al primo inserimento).
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel textAlign="left" variant="listTitle">
              Situazioni articolate
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
      </StyledList>
    </>
  );
};

export default AssocaafModello730;
