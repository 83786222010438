import { useTrackAnalytics } from '@laborability/commons';
import {
  LBTImage,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';

export default function SpecialCases() {
  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Menu - Casi particolari' },
    { key: 'page_location', value: '/menu/casi-particolari/' },
  ]);

  return (
    <Section>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Casi particolari
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Agevolazioni con requisiti di accesso rari
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTImage image="chasingMoney" />
      <LBTSpacer spacing={2} />
      <LBTSpacer spacing={2} />
    </Section>
  );
}
