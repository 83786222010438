import { useEffect, useMemo, useState } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
// import usePageTracking from '../hooks/useTrackLocation';
import ScrollToTop from './ScrollToTop';
import { BackdropLoader, QuestionaryContext } from '@laborability/components';
import NavMenu from './Layouts/NavMenu';
import Footer from './Layouts/Footer';
import { navMenuState, useBreakpoint, useLoading } from '@laborability/commons';

type HandlePageTitle = {
  page_title: string;
};

const Layout = () => {
  const isLoading = useLoading();
  const matches = useMatches();
  const bgC = useRecoilValue(navMenuState);
  const { isDesktop } = useBreakpoint();
  const [step, setStep] = useState<number>(0);
  const [pageId, setPageId] = useState<number>(0);
  // usePageTracking();

  const pageTitle = useMemo(() => {
    const pHandle = matches.find(
      match =>
        'handle' in match &&
        match.handle &&
        (match.handle as HandlePageTitle).page_title,
    );
    return pHandle
      ? (pHandle.handle as HandlePageTitle).page_title
      : 'Laborability';
  }, [matches]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <QuestionaryContext.Provider value={{ pageId, setPageId, step, setStep }}>
      {isLoading && <BackdropLoader />}
      <ScrollToTop />
      <NavMenu backgroundColor={bgC} />
      <div
        style={{
          backgroundColor: bgC,
          minHeight: `calc(100vh - ${isDesktop ? 100 : 64}px)`,
          marginTop: isDesktop ? '100px' : '64px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Outlet />
      </div>
      <Footer />
    </QuestionaryContext.Provider>
  );
};

export default Layout;
