import { LBTLabel, LBTSpacer } from '@laborability/components';
import BoldSpan from './BoldSpan';
import AnswerBoxList from './StyledList';

const AnswerBoxOne: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      Il lavoratore può cedere una{' '}
      <BoldSpan>quota della propria retribuzione</BoldSpan> - che{' '}
      <BoldSpan>non può eccedere un quinto dello stipendio</BoldSpan> netto
      mensile - nonché l'
      <BoldSpan>intero trattamento di fine rapporto</BoldSpan> (TFR), che gli
      istituti finanziari normalmente chiedono di vincolare a{' '}
      <BoldSpan>garanzia</BoldSpan> del rientro del prestito, qualora il
      rapporto di lavoro cessi prima che il debito sia stato estinto.
    </LBTLabel>
  );
};

const AnswerBoxTwo: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      Il lavoratore deve essere titolare di un{' '}
      <BoldSpan>rapporto di lavoro a tempo indeterminato</BoldSpan>, anche
      parziale, o di un <BoldSpan>rapporto di lavoro parasubordinato</BoldSpan>{' '}
      (co.co.co, agenti e rappresentanti di commercio) e percepire uno{' '}
      <BoldSpan>stipendio fisso e continuativo.</BoldSpan> Se il lavoratore ha
      un rapporto di lavoro <BoldSpan>a tempo determinato</BoldSpan>, potrà
      stipulare una cessione solo se questa ha una durata pari o inferiore a
      quella del rapporto di lavoro.
    </LBTLabel>
  );
};

const AnswerBoxThree: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      Non può cedere lo stipendio il lavoratore:
      <AnswerBoxList marginTop="0px">
        <li>
          in <BoldSpan>aspettativa non retribuita;</BoldSpan>
        </li>
        <li>
          in <BoldSpan>congedo non retribuito;</BoldSpan>
        </li>
        <li>
          con <BoldSpan>età superiore a 65 anni;</BoldSpan>
        </li>
        <li>
          che abbia in{' '}
          <BoldSpan>
            sottoscritto un divieto di cessione dello stipendio
          </BoldSpan>{' '}
          con il proprio datore di lavoro.
        </li>
      </AnswerBoxList>
    </LBTLabel>
  );
};

const AnswerBoxFour: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      Il lavoratore a <BoldSpan>tempo indeterminato non</BoldSpan> può stipulare
      cessioni di durata superiore a 10 anni.
      <LBTSpacer spacing={2} isFixed />
      Il lavoratore a <BoldSpan>tempo determinato</BoldSpan> non può stipulare
      cessioni la cui durata superi quella del rapporto di lavoro.
      <LBTSpacer spacing={2} isFixed />
      Il{' '}
      <BoldSpan>
        lavoratore a cui mancano meno di 10 anni alla pensione
      </BoldSpan>{' '}
      non può stipulare cessioni di durata superiore al tempo residuo per
      l'accesso alla pensione.
      <LBTSpacer spacing={2} isFixed />
      Il <BoldSpan>lavoratore parasubordinato</BoldSpan> non può stipulare una
      cessione la cui durata ecceda la data di scadenza del contratto.
    </LBTLabel>
  );
};

const AnswerBoxFive: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      Dal momento in cui riceve la notifica della cessione, il{' '}
      <BoldSpan>datore di lavoro</BoldSpan> è{' '}
      <BoldSpan>obbligato a eseguire una trattenuta sullo stipendio</BoldSpan>{' '}
      corrispondente alla quota fissa ceduta, indicata nell'atto di cessione. Le
      quote trattenute devono essere{' '}
      <BoldSpan>
        versate dal datore all'istituto finanziario entro il mese successivo
      </BoldSpan>{' '}
      a quello cui si riferiscono. Nel caso in cui il lavoratore subisca una{' '}
      <BoldSpan>riduzione di stipendio</BoldSpan> superiore a un terzo, il
      datore di lavoro è tenuto a comunicarlo all'istituto finanziario, e a
      riproporzionare la quota ceduta.
      <LBTSpacer spacing={2} isFixed />
      <BoldSpan>
        Se il rapporto di lavoro cessa prima che sia ripagato il prestito
      </BoldSpan>
      , il datore di lavoro deve darne comunicazione all'istituto finanziario,
      il quale invia una comunicazione che attesta il debito residuo dovuto
      (cosiddetto “conto estintivo”). Il datore di lavoro eseguirà inoltre la
      trattenuta della quota fissa e del TFR fino alla concorrenza del debito
      residuo indicato. In genere i contratti di cessione stabiliscono che “ogni
      altra indennità” o “somma” versata in conseguenza della cessazione del
      rapporto debba essere trattenuta e versata all'istituto finanziario, sino
      alla concorrenza del debito residuo.
    </LBTLabel>
  );
};

const AnswerBoxSix: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      In presenza di una cessione dello stipendio{' '}
      <BoldSpan>non si può stipulare una seconda cessione</BoldSpan> salvo che
      la somma sia diretta a estinguere la cessione precedente.
      <LBTSpacer spacing={2} isFixed />
      <LBTSpacer spacing={2} isFixed />
      Per fare ciò devono essere rispettati determinati{' '}
      <BoldSpan>tempi:</BoldSpan>
      <AnswerBoxList marginTop="0px">
        <li>
          se si è già instaurata una cessione di durata fino a 5 anni, si devono
          attendere almeno <BoldSpan>2 anni</BoldSpan> per stipularne una
          seconda;
        </li>
        <li>
          se si è già instaurata una cessione di durata fino a 10 anni, si
          devono attendere almeno <BoldSpan>4 anni</BoldSpan> per stipularne una
          seconda
        </li>
      </AnswerBoxList>
    </LBTLabel>
  );
};

const AnswerBoxSeven: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      Lo{' '}
      <BoldSpan>
        stipendio può essere contemporaneamente oggetto di una cessione e di
      </BoldSpan>{' '}
      uno o più <BoldSpan>pignoramenti</BoldSpan>, purché nel{' '}
      <BoldSpan>limite</BoldSpan> complessivo della{' '}
      <BoldSpan>metà dello stipendio</BoldSpan> stesso.
      <LBTSpacer spacing={2} isFixed />
      Se lo stipendio è gravato da una cessione e viene successivamente
      notificato un pignoramento, la quota pignorabile non potrà eccedere la
      differenza tra la metà della retribuzione e la quota già oggetto di
      cessione, fermo restando il limite del un quinto.
      <LBTSpacer spacing={2} isFixed />
      Se, invece, lo stipendio è gravato da un pignoramento e successivamente
      viene notificata una cessione, la quota cedibile non potrà eccedere la
      differenza di due quinti della retribuzione e la quota oggetto di
      pignoramento, fermo restando il limite di un quinto.
    </LBTLabel>
  );
};

export {
  AnswerBoxFive,
  AnswerBoxFour,
  AnswerBoxOne,
  AnswerBoxSeven,
  AnswerBoxSix,
  AnswerBoxThree,
  AnswerBoxTwo,
};
