export const IconTrasporti = ({ locked = false }: { locked?: boolean }) => {
  return locked ? (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.63972 20.8326L12.0897 13.9293C13.0451 12.0182 14.514 10.411 16.3317 9.28799C18.1494 8.16499 20.244 7.57058 22.3806 7.57143H25.6597C28.4622 7.57132 31.1684 8.5946 33.2696 10.449C35.3709 12.3034 36.7227 14.8613 37.071 17.6421L37.5047 21.102C38.8946 21.5423 40.1037 21.9497 41.1321 22.3801C42.4037 22.9059 43.5044 23.5071 44.3784 24.3187C46.2513 26.047 46.6817 28.3437 46.6817 31.36C46.6817 32.97 46.1593 34.672 45.374 36.0816C44.1911 38.2041 41.648 38.8383 39.2166 38.8383H9.72729C7.263 38.8383 4.963 37.961 3.70129 35.8417C2.69257 34.1529 2.32129 31.9547 2.32129 29.7171C2.32129 25.5311 4.00029 23.1457 5.952 21.8939C6.77242 21.3727 7.68457 21.0125 8.63972 20.8326Z"
        fill="#9B9B9B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.6169 14.9971C25.9742 15.4074 26.154 15.9426 26.1171 16.4853C26.0801 17.028 25.8293 17.5338 25.4198 17.8919C24.8612 18.3847 24.0595 19.5577 23.2611 21.0231C23.2567 21.0297 23.2523 21.0363 23.2479 21.0429L26.6191 20.4416C26.9572 20.381 27.3051 20.4062 27.6309 20.5149C27.9568 20.6235 28.2501 20.8122 28.4842 21.0636C28.7183 21.315 28.8855 21.621 28.9707 21.9538C29.0558 22.2866 29.0562 22.6354 28.9717 22.9683C28.5577 24.6046 27.6278 26.3953 26.7439 27.8509C26.0035 29.0915 25.1628 30.2695 24.2304 31.3731C24.0539 31.5834 23.837 31.7562 23.5925 31.8812C23.3481 32.0062 23.081 32.081 22.8072 32.101C22.5334 32.121 22.2583 32.0859 21.9983 31.9978C21.7383 31.9097 21.4986 31.7703 21.2933 31.5879C21.0881 31.4055 20.9215 31.1839 20.8035 30.936C20.6854 30.6881 20.6182 30.4191 20.6059 30.1448C20.5936 29.8705 20.6365 29.5966 20.7319 29.3391C20.8273 29.0817 20.9734 28.846 21.1615 28.646C22.0675 27.5613 22.8715 26.3954 23.5634 25.1631L20.0937 25.7809C19.74 25.8444 19.3759 25.8141 19.0376 25.6929C18.6994 25.5717 18.3988 25.3639 18.1661 25.0902C17.9333 24.8165 17.7764 24.4865 17.711 24.1332C17.6457 23.7798 17.6742 23.4156 17.7937 23.0767C18.1091 22.1797 18.8122 20.5993 19.6534 19.0616C20.4584 17.583 21.5558 15.8186 22.7189 14.8C22.9221 14.6226 23.1583 14.487 23.4139 14.4009C23.6696 14.3148 23.9397 14.2799 24.2088 14.2982C24.4779 14.3165 24.7408 14.3877 24.9824 14.5076C25.224 14.6275 25.4396 14.7939 25.6169 14.9971Z"
        fill="#D4D4D4"
      />
      <path
        d="M13.8211 43.7899C16.9918 43.7899 18.7759 42.009 18.7759 38.8383C18.7759 35.6676 16.9918 33.8834 13.8211 33.8834C10.6504 33.8834 8.86621 35.6676 8.86621 38.8383C8.86621 42.009 10.6504 43.7899 13.8211 43.7899Z"
        fill="#D4D4D4"
      />
      <path
        d="M35.1785 43.7899C38.3492 43.7899 40.1333 42.009 40.1333 38.8383C40.1333 35.6676 38.3492 33.8834 35.1785 33.8834C32.0078 33.8834 30.2236 35.6676 30.2236 38.8383C30.2236 42.009 32.0078 43.7899 35.1785 43.7899Z"
        fill="#D4D4D4"
      />
    </svg>
  ) : (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.13972 20.8324L11.5897 13.9291C12.5451 12.018 14.014 10.4108 15.8317 9.28785C17.6494 8.16485 19.744 7.57045 21.8806 7.57129H25.1597C27.9622 7.57118 30.6684 8.59446 32.7696 10.4489C34.8709 12.3033 36.2227 14.8612 36.571 17.642L37.0047 21.1019C38.3946 21.5421 39.6037 21.9496 40.6321 22.38C41.9037 22.9057 43.0044 23.507 43.8784 24.3186C45.7513 26.0469 46.1817 28.3436 46.1817 31.3599C46.1817 32.9699 45.6593 34.6719 44.874 36.0814C43.6911 38.204 41.148 38.8381 38.7166 38.8381H9.22729C6.763 38.8381 4.463 37.9609 3.20129 35.8416C2.19257 34.1527 1.82129 31.9546 1.82129 29.717C1.82129 25.531 3.50029 23.1456 5.452 21.8937C6.27242 21.3726 7.18457 21.0124 8.13972 20.8324Z"
        fill="#386CCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1169 14.9971C25.4742 15.4073 25.654 15.9425 25.6171 16.4852C25.5801 17.0279 25.3293 17.5338 24.9198 17.8918C24.3612 18.3847 23.5595 19.5577 22.7611 21.0231C22.7567 21.0297 22.7523 21.0363 22.7479 21.0428L26.1191 20.4415C26.4572 20.381 26.8051 20.4062 27.1309 20.5148C27.4568 20.6235 27.7501 20.8122 27.9842 21.0635C28.2183 21.3149 28.3855 21.621 28.4707 21.9538C28.5558 22.2865 28.5562 22.6353 28.4717 22.9683C28.0577 24.6045 27.1278 26.3953 26.2439 27.8508C25.5035 29.0915 24.6628 30.2695 23.7304 31.3731C23.5539 31.5834 23.337 31.7562 23.0925 31.8812C22.8481 32.0062 22.581 32.0809 22.3072 32.101C22.0334 32.121 21.7583 32.0859 21.4983 31.9978C21.2383 31.9096 20.9986 31.7703 20.7933 31.5879C20.5881 31.4055 20.4215 31.1838 20.3035 30.936C20.1854 30.6881 20.1182 30.4191 20.1059 30.1448C20.0936 29.8705 20.1365 29.5965 20.2319 29.3391C20.3273 29.0817 20.4734 28.846 20.6615 28.646C21.5675 27.5613 22.3715 26.3954 23.0634 25.1631L19.5937 25.7808C19.24 25.8444 18.8759 25.8141 18.5376 25.6929C18.1994 25.5717 17.8988 25.3638 17.6661 25.0901C17.4333 24.8164 17.2764 24.4865 17.211 24.1331C17.1457 23.7798 17.1742 23.4155 17.2937 23.0767C17.6091 22.1797 18.3122 20.5993 19.1534 19.0615C19.9584 17.583 21.0558 15.8185 22.2189 14.8C22.4221 14.6226 22.6583 14.487 22.9139 14.4009C23.1696 14.3148 23.4397 14.2799 23.7088 14.2982C23.9779 14.3165 24.2408 14.3876 24.4824 14.5076C24.724 14.6275 24.9396 14.7938 25.1169 14.9971Z"
        fill="#9AE6C8"
      />
      <path
        d="M13.3211 43.7897C16.4918 43.7897 18.2759 42.0089 18.2759 38.8382C18.2759 35.6674 16.4918 33.8833 13.3211 33.8833C10.1504 33.8833 8.36621 35.6674 8.36621 38.8382C8.36621 42.0089 10.1504 43.7897 13.3211 43.7897Z"
        fill="#F7C4FB"
      />
      <path
        d="M34.6785 43.7897C37.8492 43.7897 39.6333 42.0089 39.6333 38.8382C39.6333 35.6674 37.8492 33.8833 34.6785 33.8833C31.5078 33.8833 29.7236 35.6674 29.7236 38.8382C29.7236 42.0089 31.5078 43.7897 34.6785 43.7897Z"
        fill="#F7C4FB"
      />
    </svg>
  );
};
