import {
  CardVariant,
  COLORS,
  IconCrossComponent,
  IconSlidersComponent,
  IconsSize,
  IconsStyle,
  LBTButton,
  LBTButtonFilter,
  LBTCard,
  LBTDivider,
  LBTDividerSize,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  AdvancedSearchMisura,
  Category,
  Misura,
  advancedSearchMisuraCallback,
  benefitsState,
  categoriesState,
  categoryState,
  getAllBenefitsCallback,
  getCategoryByIdCallback,
  getSubCategoriesCallback,
  measuresState,
  regionsState,
  useBreakpoint,
  slugify,
  useTrackAnalytics,
} from '@laborability/commons';
import ModalRicerca from '../../components/ModalRicerca';
import InfiniteDiscover from '../../components/InfiniteDiscover';
import { APP_ROUTES } from '../../Routes';

const StyledImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img<{ maxwidth: string }>`
  width: 100%;
  max-width: ${props => props.maxwidth};
`;
const Image = ({ src }: { src: string }) => {
  const { isDesktop } = useBreakpoint();
  return (
    <StyledImageContainer>
      <StyledImage src={src} maxwidth={!isDesktop ? '100%' : '328px'} />
    </StyledImageContainer>
  );
};

export const BonusPage = () => {
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoint();
  const { id } = useParams();
  const misure = useRecoilValue(measuresState);
  const category = useRecoilValue(categoryState);
  const subCategories = useRecoilValue(categoriesState);
  const regions = useRecoilValue(regionsState);
  const benefits = useRecoilValue(benefitsState);

  const defaultFilters = {
    category_id: Number(id),
    published: true,
    news: undefined,
    upcoming: undefined,
    expiring_soon: undefined,
    for_all_incomes: undefined,
    question_online_or_phone: undefined,
    region_id: [],
    benefit_type_id: [],
    sub_categories: [],
    national: undefined,
    regional: undefined,
  };
  const jsonDefaultFilters = JSON.stringify(defaultFilters);
  const [filters, setFilters] = useState<AdvancedSearchMisura>(defaultFilters);
  const [hasFilter, setHasFilter] = useState<boolean>(false);
  const [subCategoriesTotals, setSubCategoriesTotals] = useState<
    { id: number; total: number }[]
  >([]);

  const getCategory = useRecoilCallback(getCategoryByIdCallback, []);
  const fetchMisure = useRecoilCallback(advancedSearchMisuraCallback, []);
  const getAllSubCategories = useRecoilCallback(getSubCategoriesCallback, []);
  const getBenefits = useRecoilCallback(getAllBenefitsCallback, []);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [categoryName, setCategoryName] = useState<string>();

  const getMisure = async (
    skip: number,
    limit: number,
    progressive: boolean,
    params?: Partial<AdvancedSearchMisura>,
  ) => {
    const res = await fetchMisure({
      ...params,
      skip,
      limit,
      category_id: Number(id),
      published: true,
      progressive,
    });
    if (!res || !res.data) {
      setSubCategoriesTotals([]);
      return [];
    }
    setSubCategoriesTotals(res.data.subcategories_total);
    return res.data;
  };

  const filterToString = () => {
    const sottoCategorie = filters.sub_categories
      ?.map(
        item =>
          subCategories.find(subCategory => subCategory.id === item)?.name,
      )
      .join(', ');
    const regioni = filters.region_id
      ?.map(item => regions.find(region => region.id === item)?.name)
      .join(', ');
    const benefit = filters.benefit_type_id
      ?.map(item => benefits.find(benefit => benefit.id === item)?.name)
      .join(', ');
    const news = filters.news ? 'Disponibile' : null;
    const upcoming = filters.upcoming ? 'In arrivo' : null;
    const expiring_soon = filters.expiring_soon ? 'In scadenza' : null;
    const for_all_incomes = filters.for_all_incomes
      ? 'Per tutti i redditi'
      : null;
    const question_online_or_phone = filters.question_online_or_phone
      ? 'Domanda online o al telefono'
      : null;

    return [
      ...(sottoCategorie ? [sottoCategorie] : []),
      ...(regioni ? [regioni] : []),
      ...(benefit ? [benefit] : []),
      ...(news ? [news] : []),
      ...(upcoming ? [upcoming] : []),
      ...(expiring_soon ? [expiring_soon] : []),
      ...(for_all_incomes ? [for_all_incomes] : []),
      ...(question_online_or_phone ? [question_online_or_phone] : []),
    ].join(', ');
  };

  useEffect(() => {
    getCategory({ id: Number(id) });
    getAllSubCategories({ id: Number(id) });
    getBenefits();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (category.id?.toString() === window.location.href.split('/').pop())
      setCategoryName(category.name);
  }, [category]);

  useTrackAnalytics(
    categoryName
      ? [
          { key: 'event', value: 'page_view' },
          { key: 'page_title', value: `Categoria ${categoryName}` },
          {
            key: 'page_location',
            value: `/app/categoria/${slugify(categoryName)}`,
          },
        ]
      : [],
  );

  return (
    <>
      {category && (
        <Section backgroundColor={COLORS.getInstance().WHITE}>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="delaDisplay" component="h1">
            {category?.name}
          </LBTLabel>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="spGroteskSubtitle" component="h2">
            {category?.description ?? ''}
          </LBTLabel>
          <LBTSpacer spacing={2} />
          {category.image && (
            <Box sx={{ 'max-width': '200px' }}>
              <Image src={category.image} />
            </Box>
          )}
          <LBTSpacer spacing={2} />
          <LBTLabel variant="bodyText">
            Usa i filtri per affinare la ricerca
          </LBTLabel>
          <LBTSpacer spacing={2} />
          <Grid
            container
            spacing={isDesktop ? '12px' : '8px'}
            justifyContent={'center'}
            maxWidth={isDesktop ? '1032px' : undefined}
          >
            {subCategories?.map((subCategory: Category) => {
              if (subCategory.parent_id !== Number(id)) return null;
              return (
                <Grid item key={subCategory.id}>
                  <LBTButtonFilter
                    datatestid="cat_button_filter"
                    size="small"
                    disabled={
                      !subCategoriesTotals.find(
                        total => total.id === subCategory.id,
                      )?.total
                    }
                    onClick={() => {
                      const filteredArray = filters?.sub_categories?.filter(
                        item => item !== subCategory.id,
                      );
                      const newFilters = {
                        ...filters,
                        sub_categories:
                          filters?.sub_categories?.length !==
                          filteredArray?.length
                            ? filteredArray
                            : [
                                ...(filters?.sub_categories ?? []),
                                subCategory.id,
                              ],
                      };
                      const isDirty =
                        JSON.stringify(newFilters) !== jsonDefaultFilters;
                      setHasFilter(isDirty);
                      setFilters(newFilters as any);
                    }}
                    isSelected={
                      !!filters?.sub_categories?.find(
                        item => subCategory.id === item,
                      )
                    }
                  >
                    {subCategory.name}
                  </LBTButtonFilter>
                </Grid>
              );
            })}
          </Grid>
          <LBTSpacer spacing={2} />
          <Stack justifyContent="space-between" direction="row">
            <LBTButton
              datatestid="cat_link_ricerca_avanzata"
              size="small"
              variant="invisible"
              onClick={() => {
                setIsModalOpen(true);
              }}
              startIcon={
                <IconSlidersComponent
                  datatestid="cat_link_ricerca_avanzata"
                  size={IconsSize.MEDIUM}
                  style={IconsStyle.FILLED}
                  color={COLORS.getInstance().PRIMARY_DARK}
                />
              }
              textProps={{ noWrap: true }}
            >
              Ricerca avanzata
            </LBTButton>
            <LBTButton
              size="small"
              variant="invisible"
              disabled={!hasFilter}
              onClick={() => {
                setFilters(defaultFilters);
                setHasFilter(false);
              }}
              startIcon={
                <IconCrossComponent
                  size={IconsSize.MEDIUM}
                  style={IconsStyle.FILLED}
                  color={COLORS.getInstance().PRIMARY_DARK}
                />
              }
              textProps={{ noWrap: true }}
            >
              Cancella tutti i filtri
            </LBTButton>
          </Stack>

          <LBTSpacer spacing={2} />

          <LBTDivider
            orientation="horizontal"
            width="100%"
            size={LBTDividerSize.SMALL}
            color={COLORS.getInstance().DIVIDER}
          />
          <LBTSpacer spacing={2} />
          <InfiniteDiscover
            items={misure}
            getMoreItems={getMisure}
            params={filters}
            hasTotalItemsLabel
            customLabelDescription={` per ${category.name}: ${filterToString()}`}
            renderItem={(item: Misura) => (
              <Grid item mobile={12} desktop={6} display="flex">
                <LBTCard
                  datatestid={`cat_bonus_id_${item.id}`}
                  onClick={() =>
                    navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${item.id}`)
                  }
                  title={item.name}
                  description={item.description}
                  tag={item.categories
                    ?.find(category =>
                      item.categories!.length === 2
                        ? !!category.parent_id
                        : category.parent_id === null,
                    )
                    ?.name?.toLowerCase()}
                  news={item.news ?? false}
                  expired={item.expired ?? false}
                  expiringSoon={item.expiring_soon ?? false}
                  upcoming={item.upcoming ?? false}
                  cardVariant={CardVariant.CONTAINED}
                />
              </Grid>
            )}
          />
        </Section>
      )}
      <ModalRicerca
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        filters={filters}
        setFilters={filters => {
          const isDirty = JSON.stringify(filters) !== jsonDefaultFilters;
          setHasFilter(isDirty);
          setFilters({ ...filters, sub_categories: [] });
        }}
        isDesktop={isDesktop}
      />
    </>
  );
};

export default BonusPage;
