import { authPost } from '../../http';
import { RateService, ReportBenefit, ReportProblem } from '../types';

export const rateService = async (item: RateService) => {
  return await authPost(`api/v1/rate_service/`, item);
};

export const reportProblem = async (item: ReportProblem) => {
  return await authPost(`api/v1/report_problem/`, item);
};

export const reportBenefit = async (item: ReportBenefit) => {
  return await authPost(`api/v1/report_benefit/`, item);
};
