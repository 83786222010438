import { ReactNode, useState } from 'react';
import EmptyStar from './EmptyStar';
import FilledStar from './FilledStar';
import { COLORS } from '../../utils';
import { UnstyledButton } from '../Button';
import { useBreakpoint } from '@laborability/commons';

interface Props {
  rate: number;
  setRate: (val: number) => void;
  stars?: number;
  color?: string;
}

export default function LBTRating({
  rate,
  setRate,
  stars = 5,
  color = COLORS.getInstance().BW_GREYS_JET_BLACK,
}: Props) {
  const { isDesktop } = useBreakpoint();
  const [hoverStar, setHoverStar] = useState<number>(0);
  const currentStar = hoverStar ? hoverStar : rate;

  const getStars = () => {
    let starComponents: ReactNode[] = [];
    for (let i = 1; i <= stars; i++)
      starComponents = [
        ...starComponents,
        <UnstyledButton
          onClick={() => setRate(rate === i ? 0 : i)}
          onMouseEnter={() => setHoverStar(i)}
          onMouseLeave={() => setHoverStar(0)}
        >
          <div
            style={{
              position: 'relative',
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            {i > currentStar ? (
              <EmptyStar size={isDesktop ? 40 : 20} />
            ) : (
              <>
                {i === currentStar && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontFamily: 'Dela Gothic One',
                      //fontSize: '9px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '16px',
                      color: COLORS.getInstance().WHITE,
                    }}
                  >
                    {i}
                  </div>
                )}
                <FilledStar color={color} size={isDesktop ? 40 : 20} />
              </>
            )}
          </div>
        </UnstyledButton>,
      ];

    return starComponents;
  };

  return <div style={{ display: 'flex', gap: '24px' }}>{getStars()}</div>;
}
