import {
  COLORS,
  LBTButton,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Box } from '@mui/material';
import LogoSF from '../assets/images/logo-sf.png';
import { Image } from '../pages/statics/Fifth';

export const SolaryFits = () => {
  return (
    <Section backgroundColor={COLORS.getInstance().MINT_TONIC_IPERLIGHT}>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h3">
        Vuoi approfittare della cessione del quinto?
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle">
        Attraverso la partnership con SalaryFits, avrai a disposizione un
        supporto esclusivo per capire come poter sfruttarlo al meglio!
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <Box sx={{ 'max-width': '250px' }}>
        <Image src={LogoSF} />
      </Box>

      <LBTSpacer spacing={4} />
      <LBTLabel variant="bodyText">
        Descrizione del partner ed ut perspiciatis unde omnis iste natus error
        sit voluptatem accusantium doloremque
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <LBTButton
        datatestid="btn-salaryfits"
        color="primary-negative"
        variant="contained"
        children="Scopri il servizio"
        onClick={() => {
          console.log('TODO');
        }}
      ></LBTButton>
      <LBTSpacer spacing={4} />
    </Section>
  );
};
