import Hammer from 'hammerjs';
import { useEffect, useRef } from 'react';

export type SWIPE_DIRECTION = 'left' | 'right';

const useSwipeHook = (
  elRef: any,
  onSwipeChange: (swipeDirection: SWIPE_DIRECTION, page: number) => void,
  numberOfItems: number,
  currentPage: number,
) => {
  const page = useRef(currentPage);
  const handleChange = (direction: SWIPE_DIRECTION, tmpPage: number) => {
    page.current = tmpPage;
    onSwipeChange(direction, tmpPage);
  };

  useEffect(() => {
    page.current = currentPage;
  }, [currentPage]);

  useEffect(() => {
    if (!elRef || !elRef.current) {
      console.error('UseSwipe hook need reference of swipe container');
      return;
    }

    const container = elRef.current;
    const HammerScroll = new Hammer.Manager(container);
    const Swipe = new Hammer.Swipe();

    HammerScroll.add(Swipe);
    HammerScroll.on('swiperight', () => {
      const p = page.current > 1 ? page.current - 1 : page.current;
      handleChange('right', p);
    });
    HammerScroll.on('swipeleft', () => {
      const p = page.current < numberOfItems ? page.current + 1 : page.current;
      handleChange('left', p);
    });

    return () => {
      HammerScroll.off('swipeleft');
      HammerScroll.off('swiperight');
    };
  }, [elRef]);
};
export default useSwipeHook;
