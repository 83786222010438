export default function IconQuestionaryStep1() {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6749 3.94544C11.0563 3.65645 11.5221 3.5 12.001 3.5C12.4799 3.5 12.9456 3.65645 13.327 3.94544L14.292 4.67645C17.1267 6.82562 19.555 9.46158 21.4633 12.4608L21.7732 12.9467C21.9136 13.1673 21.992 13.4215 22.0002 13.6826C22.0085 13.9438 21.9464 14.2024 21.8203 14.4314C21.6942 14.6605 21.5088 14.8515 21.2834 14.9845C21.0581 15.1176 20.8011 15.1878 20.5393 15.1879H19.529C19.5875 16.5303 19.5144 17.8741 19.3097 19.2034C19.2111 19.8432 18.8862 20.4266 18.3938 20.8479C17.9014 21.2692 17.274 21.5005 16.6254 21.5H13.8285V16.6908C13.8285 16.4513 13.7812 16.2141 13.6894 15.9928C13.5976 15.7715 13.4629 15.5705 13.2932 15.4011C13.1235 15.2317 12.9221 15.0974 12.7003 15.0057C12.4786 14.9141 12.241 14.8669 12.001 14.8669C11.761 14.8669 11.5233 14.9141 11.3016 15.0057C11.0799 15.0974 10.8784 15.2317 10.7087 15.4011C10.539 15.5705 10.4044 15.7715 10.3125 15.9928C10.2207 16.2141 10.1734 16.4513 10.1734 16.6908V21.5H7.37656C6.72828 21.4999 6.10143 21.2683 5.60937 20.8471C5.11732 20.4258 4.79252 19.8428 4.69373 19.2034C4.48904 17.8756 4.41594 16.5288 4.47442 15.1879H3.46269C3.20086 15.1878 2.94385 15.1176 2.71851 14.9845C2.49316 14.8515 2.30776 14.6605 2.18167 14.4314C2.05558 14.2024 1.99343 13.9438 2.00171 13.6826C2.00999 13.4215 2.08839 13.1673 2.22874 12.9467L2.53722 12.4608C4.4459 9.46146 6.87477 6.82549 9.70997 4.67645L10.6749 3.94544Z"
        fill="#282828"
      />
    </svg>
  );
}
