import {
  reportBenefitCallback,
  useTrackAnalytics,
} from '@laborability/commons';
import {
  LBTButton,
  LBTLabel,
  LBTSpacer,
  LBTTextField,
  Section,
} from '@laborability/components';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import ThankYouPage from '../../components/ThankYouPage';

export default function MeasureReport() {
  const sendReport = useRecoilCallback(reportBenefitCallback, []);
  const [isReportCompleted, setIsReportCompleted] = useState(false);
  const [values, setValues] = useState<{ name: string; url: string }>({
    name: '',
    url: '',
  });

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Menu - Segnala una agevolazione' },
    { key: 'page_location', value: '/menu/segnala-agevolazione/' },
  ]);

  return (
    <Section>
      {isReportCompleted ? (
        <ThankYouPage
          title="Grazie per la segnalazione"
          subtitle="Il tuo contributo è prezioso e utile a tutte le persone, perché ci permette di arricchire la mappa delle agevolazioni. Quindi davvero grazie grazie grazie!"
        />
      ) : (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="delaDisplay" component="h1">
            Segnala un'agevolazione
          </LBTLabel>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="bodyText">
            Compila i campi per aiutarci nell’impresa di mappare tutte le
            opportunità che esistono sul territorio italiano
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <LBTTextField
            label="Nome del bonus"
            type="text"
            name="name"
            onChange={value => setValues({ ...values, name: value ?? '' })}
            value={values.name}
            required
            error={!values.name}
            helperText={!values.name ? 'Campo obbligatorio' : undefined}
            sx={{ maxWidth: '680px' }}
          />
          <LBTSpacer spacing={4} />
          <LBTTextField
            label="Pagina o url"
            type="text"
            name="url"
            onChange={value => setValues({ ...values, url: value ?? '' })}
            value={values.url}
            required
            error={!values.url}
            helperText={
              !values.url
                ? 'Campo obbligatorio'
                : 'Inserisci una fonte ufficiale (es. Ministero, Inps o altro ente)'
            }
            sx={{ maxWidth: '680px' }}
          />
          <LBTSpacer spacing={4} />
          <LBTButton
            variant="contained"
            disabled={!values.name || !values.url}
            onClick={async () => {
              const res = await sendReport({
                page_url: values.url,
                benefit_name: values.name,
              });
              if (res?.data) setIsReportCompleted(true);
            }}
            fullWidth
            maxWidth="680px"
          >
            Avanti
          </LBTButton>
          <LBTSpacer spacing={4} />
        </>
      )}
    </Section>
  );
}
