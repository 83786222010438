import {
  currentUserState,
  getUserProfileCallback,
} from '@laborability/commons';
import {
  LBTDivider,
  LBTImage,
  LBTLabel,
  LBTListItem,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { APP_ROUTES } from '../../Routes';
import { useNavigate } from 'react-router-dom';

export default function UserAccount() {
  const navigate = useNavigate();
  const getUser = useRecoilCallback(getUserProfileCallback, []);
  const user = useRecoilValue(currentUserState);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Section maxWidth={'680px'} width="100%" alignSelf="center">
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Account
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <LBTListItem title="Nome" description={user.first_name} />
      <LBTDivider />
      <LBTListItem title="E-mail" description={user.email} />
      <LBTListItem
        title="Azienda"
        description={user.companies?.map(item => item.name).join(', ')}
      />
      <LBTListItem
        title="CCNL"
        description={user.CCNL_contracts?.map(item => item.name).join(', ')}
      />
      <LBTListItem
        title="Enti bilaterali"
        description={user.bilateral_entities?.map(item => item.name).join(', ')}
      />
      <LBTDivider />
      <LBTListItem
        title="Informativa sulla privacy"
        description="Questo servizio è conforme agli standard GDPR 2024"
        rightItem={
          <LBTLabel
            variant="bodyLink"
            onClick={() => navigate(`/${APP_ROUTES.PRIVACY_POLICY}`)}
            component="a"
            sx={{ fontWeight: 700 }}
          >
            Leggi
          </LBTLabel>
        }
      />
      <LBTSpacer spacing={2} />
      <LBTImage image="amaca" />
      <LBTSpacer spacing={4} />
    </Section>
  );
}
