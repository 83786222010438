export default function IconPoint() {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00098 10.4992C4.00098 6.08134 7.58233 2.5 12.0001 2.5C16.418 2.5 19.9993 6.08134 19.9993 10.4992C19.9993 13.1564 18.7909 15.6222 17.3971 17.5934C15.9964 19.5737 14.3543 21.131 13.3669 21.9866C12.577 22.6711 11.4233 22.6711 10.6334 21.9866C9.64596 21.131 8.00388 19.5737 6.60329 17.5932C5.20937 15.6222 4.00098 13.1564 4.00098 10.4992ZM14.6658 10.0738C14.6658 11.78 13.7061 12.7398 11.9999 12.7398C10.2937 12.7398 9.33397 11.78 9.33397 10.0738C9.33397 8.36763 10.2937 7.40789 11.9999 7.40789C13.7061 7.40789 14.6658 8.36763 14.6658 10.0738Z"
        fill="#282828"
      />
    </svg>
  );
}
