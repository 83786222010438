import {
  LBTDivider,
  LBTDividerSize,
  LBTLabel,
  LBTSpacer,
} from '@laborability/components';
import Box, { BoxProps } from '@mui/material/Box';
import List, { ListProps } from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import React from 'react';
import styled from 'styled-components';

export type LBTListProps = ListProps & {
  datatestid?: string;
};

export type LBTListItemProps = ListItemProps & {
  datatestid?: string;
};

const StyledList = styled(List)<ListProps & {}>(({ ...props }) => ({}));

const StyledListItem = styled(ListItem)<ListItemProps & {}>(({ ...props }) => ({
  padding: '16px 4px',
  minHeight: '57px',
}));

const CustomBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const AssocaafIMU: React.FC<LBTListProps> = ({
  datatestid = '',
  ...props
}: LBTListProps) => {
  return (
    <>
      <LBTLabel variant="smallCapsBold" component="h5" textAlign="left">
        costi
      </LBTLabel>
      <LBTSpacer spacing={2} isFixed />
      <StyledList disablePadding {...props}>
        <LBTDivider
          size={LBTDividerSize.MEDIUM}
          orientation="horizontal"
          width="100%"
        />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel
              textAlign="left"
              variant="listTitle"
              sx={{ maxWidth: '80%' }}
            >
              Primo immobile
            </LBTLabel>

            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              18€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel
              textAlign="left"
              variant="listTitle"
              sx={{ maxWidth: '80%' }}
            >
              Dal secondo immobile nello stesso comune
            </LBTLabel>
            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              10€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel
              textAlign="left"
              variant="listTitle"
              sx={{ maxWidth: '80%' }}
            >
              Dal secondo immobile in altro comune
            </LBTLabel>
            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              5€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
        <LBTDivider orientation="horizontal" width="100%" />
        <StyledListItem disablePadding>
          <CustomBox>
            <LBTLabel
              textAlign="left"
              variant="listTitle"
              sx={{ maxWidth: '80%' }}
            >
              Servizio aggiuntivo di addebito del pagamento sul proprio conto
              corrente
            </LBTLabel>

            <LBTLabel
              textAlign="right"
              variant="buttonLarge"
              component="strong"
            >
              20€
            </LBTLabel>
          </CustomBox>
        </StyledListItem>
      </StyledList>
    </>
  );
};

export default AssocaafIMU;
