import { LBTLabel, LBTSpacer } from '@laborability/components';
import BoldSpan from './BoldSpan';

const AnswerBoxOne: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      La fine del rapporto comporta che la banca non può più fare affidamento
      sulla quota di stipendio ceduta perché è terminato il rapporto che
      garantiva lo stipendio mensile. <LBTSpacer spacing={2} isFixed />
      Tuttavia, anche in questo caso, sono previste alcune tutele a favore del
      soggetto che ha prestato il denaro.
      <LBTSpacer spacing={2} isFixed />
      Innanzitutto, la maggior parte degli istituti prevede, tra le clausole che
      regolano la cessione,{' '}
      <BoldSpan>
        la devoluzione dell'intera somma del TFR in acconto o a saldo del
        finanziamento
      </BoldSpan>
      . Significa che, se il prestito non è stato interamente pagato, il TFR
      viene versato direttamente dall'azienda alla finanziaria, a copertura del
      finanziamento.
      <LBTSpacer spacing={2} isFixed />A tal fine, prima del pagamento del TFR,
      l'azienda deve dare comunicazione della cessazione del rapporto alla
      banca/finanziaria, che la informerà dell'eventuale debito residuo: in
      questo caso l'azienda devolverà il TFR per il pagamento della somma
      residua.
      <LBTSpacer spacing={2} isFixed /> Si tratta di una deroga alla disciplina
      generale, che, per quanto riguarda lo stipendio, consente la cessione nel
      limite massimo del quinto.
    </LBTLabel>
  );
};

const AnswerBoxTwo: React.FC = () => {
  return (
    <LBTLabel variant="bodyText" textAlign="left">
      Nel caso in cui un dipendente abbia in corso una cessione del quinto e
      cambi lavoro, lo scenario dipende dalle{' '}
      <BoldSpan>condizioni contrattuali del finanziamento</BoldSpan>. Molti
      contratti prevedono una <BoldSpan>ultra vigenza della cessione</BoldSpan>
      anche nel caso in cui si cambi posto di lavoro.
      <LBTSpacer spacing={2} isFixed />
      Cosa significa? In questa ipotesi,{' '}
      <BoldSpan>il debito residuo si trasferisce</BoldSpan> al nuovo rapporto di
      lavoro. Il dipendente è obbligato a informare la banca/finanziaria del
      nuovo datore di lavoro, al quale sarà notificata la cessione dello
      stipendio.
      <LBTSpacer spacing={2} isFixed />
      Il nuovo datore di lavoro <BoldSpan>si può opporre?</BoldSpan> No. La
      cessione è un contratto tra lavoratore e banca/finanziaria, rispetto al
      quale l'azienda è un soggetto terzo.
      <LBTSpacer spacing={2} isFixed />
      Se, invece, il contratto non prevede questo passaggio, il lavoratore, nel
      caso in cui debba risarcire ancora una parte di prestito, dovrà far fronte
      autonomamente al pagamento del debito, oppure{' '}
      <BoldSpan>rinegoziare una nuova cessione</BoldSpan> con la finanziaria.
    </LBTLabel>
  );
};

export { AnswerBoxOne, AnswerBoxTwo };
