import {
  COLORS,
  IconThankYou,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';

interface Props {
  title: string;
  subtitle: string;
}

export default function ThankYouPage({ title, subtitle }: Props) {
  return (
    <Section backgroundColor={COLORS.getInstance().PRIMARY_MAIN} width="100%">
      <LBTSpacer spacing={2} />
      <IconThankYou />
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        {title}
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        {subtitle}
      </LBTLabel>
      <LBTSpacer spacing={4} />
    </Section>
  );
}
