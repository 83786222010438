export default function IconQuestionaryStep1() {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1435 3.5C13.8046 3.5 13.5124 3.73816 13.444 4.07009C13.0833 5.81986 13.0323 7.48764 13.2909 9.21422L8.3565 9.21422L7.80016 6.12293C7.33943 3.56284 4.11119 2.63911 2.31479 4.43551C1.89637 4.85393 1.89637 5.53231 2.31479 5.95074C2.73321 6.36916 3.4116 6.36916 3.83002 5.95074C4.44596 5.3348 5.54766 5.70496 5.69119 6.50247L6.292 9.84093C6.28849 9.86963 6.28669 9.89886 6.28669 9.9285L6.28669 11.3571C6.28669 13.6768 7.52722 15.1559 9.13045 16.0087C10.6892 16.8379 12.6034 17.0926 14.1438 17.0714C15.6842 17.0926 17.5984 16.8379 19.1573 16.0087C20.7604 15.1559 22.001 13.6768 22.001 11.3571L22.001 9.9285L22.0007 9.90644C21.9887 6.36622 19.1151 3.5 15.5721 3.5L14.1435 3.5ZM9.85812 18.5C9.10666 18.5 8.46916 18.7124 8.01986 19.1617C7.57058 19.611 7.35812 20.2486 7.35812 21C7.35812 21.7514 7.57058 22.389 8.01986 22.8383C8.46916 23.2876 9.10666 23.5 9.85812 23.5C10.6096 23.5 11.2471 23.2876 11.6964 22.8383C12.1457 22.389 12.3581 21.7514 12.3581 21C12.3581 20.2486 12.1457 19.611 11.6964 19.1617C11.2471 18.7124 10.6096 18.5 9.85812 18.5ZM18.4295 18.5C17.6781 18.5 17.0405 18.7124 16.5913 19.1617C16.142 19.611 15.9295 20.2486 15.9295 21C15.9295 21.7514 16.142 22.389 16.5913 22.8383C17.0405 23.2876 17.6781 23.5 18.4295 23.5C19.181 23.5 19.8185 23.2876 20.2678 22.8383C20.7171 22.389 20.9295 21.7514 20.9295 21C20.9295 20.2486 20.7171 19.611 20.2678 19.1617C19.8185 18.7124 19.181 18.5 18.4295 18.5Z"
        fill="#282828"
      />
    </svg>
  );
}
