import {
  CardVariant,
  COLORS,
  KeycloakContext,
  LBTButton,
  LBTButtonIllustrative,
  LBTCard,
  LBTChip,
  LBTDialog,
  LBTImage,
  LBTLabel,
  LBTPagination,
  LBTSpacer,
  MandatoryDialog,
  Section,
} from '@laborability/components';
import {
  Misura,
  SWIPE_DIRECTION,
  categoriesState,
  currentCompanyConfigState,
  getAllTagsCallback,
  getCategoriesHomePageCallback,
  getMisureMostPopularCallback,
  measuresState,
  tagsState,
  useBreakpoint,
  useSwipeHook,
  useTrackAnalytics,
} from '@laborability/commons';
import { Box, BoxProps, Grid, Stack } from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { APP_ROUTES } from '../Routes';
import BoldSpan from '../components/answerBoxes/BoldSpan';
import ElsaLogo from '../assets/images/elsa.svg';

const StyledImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img<{ maxwidth: string }>`
  width: 100%;
  max-width: ${props => props.maxwidth};
`;
export const Image = ({ src }: { src: string }) => {
  return (
    <StyledImageContainer>
      <StyledImage src={src} maxwidth={'100%'} />
    </StyledImageContainer>
  );
};

const StyledBonusBoxContainer = styled(Box)<BoxProps & { maxwidth: string }>(
  ({ maxwidth }) => ({
    width: '100%',
    maxWidth: maxwidth,
  }),
);

const StyledSituationsBoxContainer = styled(Box)<
  BoxProps & { maxwidth: string }
>(({ maxwidth }) => ({
  width: '100%',
  maxWidth: maxwidth,
}));

const SectionExplore = () => {
  const { isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  const categories = useRecoilValue(categoriesState);

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={4} />
      <LBTLabel variant="delaDisplay" component="h3">
        Esplora i bonus
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle">
        Bonus e incentivi sono organizzati in aree tematiche per aiutarti a
        navigare secondo i tuoi interessi
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <Grid
        container
        spacing={isDesktop ? '24px' : '16px'}
        style={{ maxWidth: isDesktop ? '1032px' : '390px', width: '100%' }}
      >
        {categories.map((category, index) => (
          <Grid item key={index} mobile={6} desktop={4}>
            <LBTButtonIllustrative
              datatestid={
                'home_button_cat_' +
                category.name
                  ?.split(' ')
                  .join('-')
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase()
              }
              onClick={() => navigate(`/${APP_ROUTES.CATEGORY}/${category.id}`)}
              icon={category.icon as React.ReactNode}
              label={category.name as string}
            />
          </Grid>
        ))}
      </Grid>
      <LBTSpacer spacing={4} />
    </Section>
  );
};

export const SectionSituations = () => {
  const { isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  const tags = useRecoilValue(tagsState);

  return (
    <Section backgroundColor={COLORS.getInstance().PRIMARY_MAIN}>
      <LBTSpacer spacing={4} />
      <LBTLabel variant="bodyText">
        Alcune situazioni sono trasversali alle aree tematiche. Le abbiamo
        raccolte per aiutarti a esplorare i bonus secondo le tue necessità
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <StyledSituationsBoxContainer maxwidth={'1032px'}>
        <Stack
          spacing={'4px'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          flexWrap={'wrap'}
        >
          {tags.map(tag => (
            <Box
              key={tag.id}
              component="div"
              sx={{ marginTop: '4px !important' }}
            >
              <LBTButton
                sx={{ maxHeight: isDesktop ? '47px' : '35px' }}
                datatestid={
                  'home_btn_tag_' +
                  tag.name
                    ?.split(' ')
                    .join('-')
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                }
                variant="contained"
                color="primary-negative"
                size={'medium'}
                onClick={() => navigate(`/${APP_ROUTES.TAG}/${tag.id}`)}
              >
                {tag.name}
              </LBTButton>
            </Box>
          ))}
        </Stack>
      </StyledSituationsBoxContainer>

      <LBTSpacer spacing={4} />
    </Section>
  );
};

const SectionBonus = () => {
  const { isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  const measures = useRecoilValue(measuresState);
  const [page, setPage] = React.useState(1);

  const swipeContainerRef = useRef<HTMLDivElement>(null);
  const onSwipe = useCallback((direction: SWIPE_DIRECTION, tmpPage: number) => {
    setPage(tmpPage);
  }, []);
  useSwipeHook(swipeContainerRef, onSwipe, measures.length, page);

  const getCardCategory = (misura: Misura) => {
    if (misura.categories?.length === 0) return '-';
    if (misura.categories?.length === 2)
      return (
        misura.categories.find(category => category.parent_id)?.name ?? '-'
      );
    return misura.categories?.[0]?.name ?? '-';
  };

  return (
    <Section backgroundColor={COLORS.getInstance().PRIMARY_IPERLIGHT}>
      <LBTSpacer spacing={4} />
      <LBTLabel variant="delaDisplay" content="h3" component="h3">
        Bonus più popolari
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle">
        Naviga tra incentivi, agevolazioni e bonus più richiesti
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <StyledBonusBoxContainer
        ref={swipeContainerRef}
        maxwidth={isDesktop ? '1032px' : '390px'}
      >
        <Grid
          container
          spacing={isDesktop ? '24px' : '16px'}
          sx={{
            maxWidth: isDesktop ? '1032px' : undefined,
            justifyContent: 'center',
          }}
        >
          {measures.map((misura: Misura, index: number) => {
            if (!isDesktop && page !== index + 1) return null;
            return (
              <Grid item mobile={12} desktop={6} key={index} display="flex">
                <LBTCard
                  onClick={() =>
                    navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${misura.id}`)
                  }
                  key={index}
                  title={misura.name}
                  description={misura.description}
                  tag={getCardCategory(misura).toLowerCase()}
                  news={misura.news ?? false}
                  expired={misura.expired ?? false}
                  expiringSoon={misura.expiring_soon ?? false}
                  cardVariant={CardVariant.WHITE}
                  upcoming={misura.upcoming ?? false}
                />
              </Grid>
            );
          })}
        </Grid>

        {!isDesktop && (
          <LBTPagination
            numberOfItems={measures.length}
            onPageChange={tmpPage => setPage(tmpPage)}
            currentPage={page}
          />
        )}
      </StyledBonusBoxContainer>
      <LBTSpacer spacing={isDesktop ? 8 : 4} isFixed />
    </Section>
  );
};

const Home = () => {
  const { hasQuestionaryAccess } = useContext(KeycloakContext);
  const { isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  const { fifth_assignment, tfr } = useRecoilValue(currentCompanyConfigState);
  const getAllCategories = useRecoilCallback(getCategoriesHomePageCallback, []);
  const getAllTags = useRecoilCallback(getAllTagsCallback, []);
  const getMeasures = useRecoilCallback(getMisureMostPopularCallback, []);
  const [isCookiesModalOpen, setIsCookiesModalOpen] = useState<boolean>(false);
  const [isFirstVisitModalOpen, setIsFirstVisitModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    getAllCategories();
    getAllTags();
    getMeasures({ limit: 4, published: true });
    // eslint-disable-next-line
  }, [isDesktop]);

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Homepage' },
    { key: 'page_location', value: '/app/homepage' },
  ]);

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().PRIMARY_MAIN}>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Centinaia di bonus e agevolazioni per te e i tuoi cari
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTImage image="telescope" />
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Avresti mai pensato che una famiglia di medio reddito può ottenere
          ogni anno in media 980 euro tra bonus e incentivi pubblici? Scopri
          quali puoi ottenere
        </LBTLabel>
        {hasQuestionaryAccess && (
          <>
            <LBTSpacer spacing={2} />
            <LBTButton
              onClick={() => {
                navigate(`/${APP_ROUTES.QUESTIONARY}`);
              }}
              variant="contained"
              color="primary-negative"
              datatestid="home_button_sblocca_agevolazioni_1"
              sx={{ width: '100%', maxWidth: '358px' }}
            >
              Sblocca i tuoi bonus
            </LBTButton>
          </>
        )}
        <LBTSpacer spacing={4} />
      </Section>
      <SectionExplore />
      <SectionSituations />
      <SectionBonus />
      {fifth_assignment && (
        <Section backgroundColor={COLORS.getInstance().TANGERINE_MEDIUM}>
          <LBTSpacer spacing={4} />
          <LBTLabel variant="delaDisplay" component="h3">
            Hai bisogno di un prestito?
          </LBTLabel>
          <LBTSpacer spacing={4} isFixed />
          <LBTLabel variant="spGroteskSubtitle">
            Puoi usare la cessione del quinto, un prestito che puoi chiedere
            alla banca e ripagare con una parte dello stipendio
          </LBTLabel>
          <LBTSpacer spacing={2} />
          <LBTImage image="moneyLoan" />
          <LBTSpacer spacing={4} isFixed />
          <LBTButton
            datatestid="home_button_cqs"
            variant="contained"
            color="primary-negative"
            size="medium"
            onClick={() => navigate(`/${APP_ROUTES.FIFTH}`)}
          >
            Leggi la guida alla cessione del quinto
          </LBTButton>
          <LBTSpacer spacing={6} />
        </Section>
      )}
      {tfr && (
        <Section backgroundColor={COLORS.getInstance().MINT_TONIC_MAIN}>
          <LBTSpacer spacing={4} />
          <LBTLabel variant="delaDisplay" component="h3">
            Cosa fare con il TFR? E la pensione integrativa serve?
          </LBTLabel>
          <LBTSpacer spacing={4} isFixed />
          <LBTLabel variant="spGroteskSubtitle">
            Per fare la scelta giusta è importante conoscere tutte le opzioni.
            Ti aiutiamo a pianificare il tuo futuro previdenziale, finanziario e
            assicurativo
          </LBTLabel>
          <LBTSpacer spacing={2} />
          <LBTImage image="pigAtBeach" />
          <LBTSpacer spacing={4} isFixed />
          <LBTLabel variant="bodyText1">
            Prenota una sessione online di 60 minuti con gli esperti di CiaoElsa
            per fare tutte le domande vuoi e ricevere informazioni specifiche
            sul tuo caso
          </LBTLabel>

          <LBTSpacer spacing={4} isFixed />
          <LBTButton
            datatestid="home_button_tfr"
            variant="contained"
            color="primary-negative"
            size="medium"
            onClick={() => navigate(`/${APP_ROUTES.SEVERANCE_PAY}`)}
          >
            Prenota una sessione a 99€
          </LBTButton>
          <LBTSpacer spacing={1} isFixed />
          <LBTLabel variant="sourceCaption">invece che 149€</LBTLabel>
          <LBTSpacer spacing={2} isFixed />
          <LBTChip
            size="medium"
            variant="filled"
            color="lemonCake"
            label={
              <>
                codice sconto: <BoldSpan>LBTSPECIAL50</BoldSpan>
              </>
            }
            sx={{ padding: '4px 8px' }}
          />
          <LBTSpacer spacing={4} isFixed />
          <Box display="flex" alignItems="center">
            <LBTLabel
              variant="sourceCaption"
              component="small"
              textAlign="center"
            >
              servizio offerto da:
            </LBTLabel>
            <Box maxHeight="16px">
              <Image src={ElsaLogo} />
            </Box>
          </Box>
          <LBTSpacer spacing={4} isFixed />
        </Section>
      )}
      <MandatoryDialog
        datatestidIcon="banner_cookie_button_x"
        datatestidButton="banner_cookie_button_ok"
        open={isCookiesModalOpen}
        onClose={() => {
          setIsCookiesModalOpen(false);
          // TODO salvataggio cookie
        }}
        title="Informativa sui cookie"
      >
        Dritto, la piattaforma di laborability dedicata al welfare pubblico,
        utilizza sul proprio sito cookie tecnici che garantiscono la normale
        navigazione e fruizione del sito, nonché cookie analitici, assimilati ai
        cookie tecnici (cfr. provvedimento dell'8 maggio 2014 e Linee guida
        cookie e altri strumenti di tracciamento del 10 giugno 2021, del Garante
        per la protezione dei dati personali), di prime e terze parti, per
        raccogliere dati statistici, in forma anonima e aggregata, sulle visite
        e l'utilizzo del sito. Consulta la cookie policy per ulteriori dettagli
        a riguardo.
      </MandatoryDialog>

      <LBTDialog
        open={isFirstVisitModalOpen}
        title="È il tuo primo accesso, grazie per la fiducia!"
        onClose={() => {
          setIsFirstVisitModalOpen(false);
        }}
        onSubmit={() => {
          setIsFirstVisitModalOpen(false);
        }}
        onSubmitLabel="Ho capito"
      >
        Prima di lanciarti nell'esplorazione un'informazione importante: con il
        primo accesso abbiamo creato il tuo account. Lo cancelleremo nel caso in
        cui l'azienda sospenda il servizio oppure nel momento in cui non sarai
        più suo dipendente
      </LBTDialog>
    </>
  );
};

export default Home;
