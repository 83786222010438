import { COLORS, LBTLabel, LBTSpacer, Section } from '@laborability/components';
import { useBreakpoint } from '@laborability/commons';
import {
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import BoldSpan from '../../components/answerBoxes/BoldSpan';

export default function PrivacyPolicy() {
  const { isDesktop } = useBreakpoint();

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Privacy Policy
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Informativa ex art. 13 Reg. Ue 2016/679
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <Stack
        style={{ flexDirection: 'column' }}
        gap={isDesktop ? '32px' : '16px'}
        marginBottom="64px"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <LBTLabel variant="bodyText" textAlign="justify">
          La società Laborability S.r.l. con sede legale in Padova (PD), Via
          Rismondo n. 2/E (di seguito “Il Titolare o la Società”). I dati di
          contatto del Titolare sono: Telefono: 049 7985454 E-mail:
          privacy@laborability.com (di seguito Laborability per brevità)
          riconosce l’importanza della protezione dei dati personali e considera
          la sua tutela uno degli obiettivi principali della propria attività.
          Prima di comunicare qualsiasi dato personale, Laborability invita a
          leggere con attenzione la presente privacy policy (“Privacy Policy”),
          poiché contiene informazioni importanti sulla tutela dei dati
          personali e sulle misure di sicurezza adottate per garantirne la
          riservatezza nel pieno rispetto della Normativa Applicabile.
          <br />
          Si precisa che la presente Privacy Policy, viene resa esclusivamente
          per il sito ______________.com (“Sito”), mentre non si applica ad
          altri siti web eventualmente consultati tramite link esterni e deve
          considerarsi quale informativa ai sensi dell’art. 13 del Reg. Ue
          2016/679 a coloro che accedo e interagiscono con il Sito. Si precisa
          che Laborability ha ritenuto di conformarsi anche a quanto previsto
          nella Raccomandazione n. 2/2001 relativa ai requisiti minimi per la
          raccolta di dati on-line nell’Unione Europea, adottata il 17 maggio
          2001 dal Gruppo di Lavoro Articolo 29.
        </LBTLabel>
        <LBTLabel variant="bodyText">***</LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          Laborability la informa che il trattamento dei suoi dati personali
          sarà improntato ai principi di liceità, correttezza, trasparenza,
          limitazione delle finalità e della conservazione, minimizzazione dei
          dati, esattezza, integrità e riservatezza. I suoi dati personali
          verranno pertanto trattati in accordo alle disposizioni legislative e
          degli obblighi di riservatezza ivi previsti.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
        >
          1. TITOLARE DEL TRATTAMENTO E DPO
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          Con la presente la società <BoldSpan>LABORABILITY S.r.l</BoldSpan> con
          sede in Padova (PD) Via Rismondo n. 2/E C.F. e P.IVA: 03471600282 i
          cui dati di contatto sono i seguenti: Tel. 049 7985454 E-mail:
          privacy@laborability.com intende informarla, ai sensi dell’art. 13 del
          Regolamento UE 2016/679 (di seguito per brevità “Regolamento” o
          “Normativa Applicabile”), che LABORABILITY S.r.l. è TITOLARE DEI DATI
          PERSONALI, che verranno forniti per le finalità proprie di gestione e
          amministrazione del Sito e che procederà al relativo trattamento anche
          per le finalità e secondo le modalità sotto riportate, qualora
          applicabili. In ogni momento, potrà rivolgersi al “Titolare del
          trattamento dei dati” per avere informazioni e/o segnalare eventuali
          problemi. La società ha nominato l’Avv. Enrico Sinigaglia quale DPO i
          cui dati di contatti sono: dpo@laborability.com
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
        >
          2. DATI PERSONALI OGGETTO DI TRATTAMENTO
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          Per “Dati Personali” si intende qualsiasi informazione riguardante una
          persona fisica identificata o identificabile con particolare
          riferimento a un identificativo come il nome, un numero di
          identificazione, dati relativi all’ubicazione, un identificativo
          online o a uno o più elementi caratteristici della sua identità
          fisica, fisiologica, psichica, economica, culturale o sociale. In
          ragione di quanto appena precisato attraverso il presente Sito
          Laborability potrà raccogliere i seguenti dati:
        </LBTLabel>
        <LBTLabel
          variant="smallCapsBold"
          width="100%"
          textAlign="left"
          component="h5"
        >
          Dati di navigazione
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          I sistemi informatici del Sito raccolgono alcuni Dati Personali la cui
          trasmissione è implicita nell’uso dei protocolli di comunicazione di
          Internet. Si tratta di informazioni che non sono raccolte per essere
          associate all’utente del Sito, ma che per loro stessa natura
          potrebbero, attraverso elaborazioni ed associazioni con dati detenuti
          da terzi, permettere di identificarlo. Tra questi ci sono gli
          indirizzi IP o i nomi a dominio dei dispositivi utilizzati per la
          connessione al Sito, gli indirizzi in notazione URI (Uniform Resource
          Identifier) delle risorse richieste, l’orario della richiesta, il
          metodo utilizzato nel sottoporre la richiesta al server, la dimensione
          del file ottenuto in risposta, il codice numerico indicante lo stato
          della risposta data dal server (buon fine, errore, ecc.) ed altri
          parametri relativi al tuo sistema operativo e ambiente informatico.
          Questi dati vengono utilizzati al fine di ricavare informazioni
          statistiche anonime sull’uso del Sito e per controllarne il corretto
          funzionamento; per permettere – vista l’architettura dei sistemi
          utilizzati – la corretta erogazione delle varie funzionalità
          richieste, per ragioni di sicurezza e di accertamento di
          responsabilità in caso di ipotetici reati informatici ai danni del
          Sito o di terzi e vengono cancellati dopo 7 giorni.
        </LBTLabel>
        <LBTLabel
          variant="smallCapsBold"
          width="100%"
          textAlign="left"
          component="h5"
        >
          Dati forniti volontariamente
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          Attraverso l’accesso al Sito (accesso non pubblico) l’utente fornisce
          volontariamente Dati Personali come il nome e cognome, l’indirizzo
          e-mail utilizzati per consentire la gestione e verifica delle
          credenziali di accesso al Sito e per dare seguito alla richiesta
          riferita al servizio offerto dal Sito. Laborability tratterà questi
          Dati nel rispetto della normativa di settore, assumendo che siano
          riferiti alla persona dell’utente o a terzi soggetti che hanno
          espressamente autorizzato l’utente a conferirli in base ad un’idonea
          base giuridica che legittima il trattamento dei Dati in questione.
          Rispetto a tali ipotesi, l’utente si pone come autonomo titolare del
          trattamento, assumendosi tutti gli obblighi e le responsabilità di
          legge. In tal senso, l’utente riconosce sul punto la più ampia manleva
          rispetto a ogni contestazione, pretesa, richiesta di risarcimento del
          danno da trattamento, etc. che dovesse pervenire a Laborability da
          terzi soggetti i cui Dati Personali siano stati trattati attraverso
          l’utilizzo del Sito da parte dell’utente, in violazione della
          Normativa Applicabile.
        </LBTLabel>
        <LBTLabel
          variant="smallCapsBold"
          width="100%"
          textAlign="left"
          component="h5"
        >
          Cookie e tecnologie affini
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          Laborability può raccoglie Dati Personali anche attraverso i cookie.
          Maggiori informazioni sull’uso dei cookie e tecnologie affini sono
          disponibili sul pop-up di gestione cookie che appare all’utente nel
          momento in cui si collegata alla pagina del Sito internet. In tale
          schermata l’Utente potrà gestire le tipologie di cookie presenti sul
          Sito, prendendo anche visione dei tempi di conservazione delle
          modalità di cancellazione.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
        >
          3. FINALITÁ, BASE GIURIDICA E NATURA OBBLIGATORIA O FACOLTATIVA DEL
          TRATTAMENTO
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          I Dati Personali che l’utente fornisce attraverso il Sito saranno
          trattati da Laborability per le seguenti finalità:
        </LBTLabel>
        <Table style={{ maxWidth: '1032px' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  <BoldSpan>Finalità</BoldSpan>
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  <BoldSpan>Tipologia dei dati</BoldSpan>
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  <BoldSpan>Base giuridica</BoldSpan>
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  <BoldSpan>Periodo di conservazione</BoldSpan>
                </LBTLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ verticalAlign: 'top' }}>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  <BoldSpan>1. Contrattuale/precontrattuale.</BoldSpan> I Dati
                  Personali trattati sono indispensabili per poter consentire
                  all’utente l’accesso al Sito
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Da ti relativi alle credenziali di accesso al Sito, dati
                  comuni identificativi dell’utente (nome, cognome, indirizzo
                  e-mail)
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Necessità di eseguire un contratto di cui l’utente è parte o
                  rispetto al quale è terzo beneficiario. art. 6 comma 1 lett.
                  b) GDPR.
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Per il tempo necessario all’erogazione del servizio (accesso e
                  fruizione dei contenuti del Sito), e comunque entro i limiti
                  di legge.
                </LBTLabel>
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'top' }}>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  <BoldSpan>3. accertare, esercitare o difendere</BoldSpan> i
                  diritti in sede giudiziaria
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Dati anagrafici e dati di contatto, dati relativi
                  all’esecuzione del contratto.
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Legittimo interesse (tutela giudiziaria) Art. 6 co 1 lett. f)
                  GDPR.
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Per il tempo necessario a all’esercizio dei diritti in sede
                  giudiziaria.
                </LBTLabel>
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'top' }}>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  <BoldSpan>4. Marketing diretto</BoldSpan> per invio, con
                  modalità automatizzate di contatto (e-mail e messaggistica
                  istantanea) e tradizionali (telefonate con operatore e posta
                  ordinaria), di materiale pubblicitario, newsletter,
                  comunicazioni promozionali e commerciali relativi a prodotti
                  e/o eventi e/o corsi di formazione in relazione, nonché
                  realizzazione di studi di mercato e analisi statistiche e
                  rilevazione del grado di soddisfazione della clientela
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Ragione sociale, Referente e dati di contratto
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Consenso (richiesto con contratto o con richiesta specifica);
                  <br />
                  <br />
                  (facoltativo e revocabile in qualsiasi momento)
                  <br />
                  <br />
                  Art. 6 co. 1 lett. a) GDPR.
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Sino alla revoca del consenso per tale finalità e/o trascorsi
                  cinque anni dall’ espressione del consenso.
                </LBTLabel>
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'top' }}>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  <BoldSpan>5. Marketing verso già clienti</BoldSpan> per
                  l’invio di comunicazioni relative a prodotti/servizi
                  contrattualizzati e/o a prodotti/servizi similari a quelli già
                  contrattualizzati (newsletter, webinar, eventi, attività
                  formative).
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Dati anagrafici e dati di contatto; dati relativi all’azienda
                  di appartenenza e ruolo ricoperto
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Legittimo interesse
                  <br />
                  <br />
                  Art. 6 co 1 lett. f) GDPR.
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Fino alla revoca del consenso.
                </LBTLabel>
              </TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'top' }}>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  <BoldSpan>6. Marketing indiretto</BoldSpan> per comunicazione
                  dei dati a terzi di seguito indicati, affinché possano
                  renderla destinataria di comunicazioni marketing.
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Dati anagrafici e dati di contatto
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Consenso (richiesto con contratto o con richiesta specifica)
                  (facoltativo e revocabile in qualsiasi momento) Art. 6 co. 1
                  lett. a) GDPR.
                </LBTLabel>
              </TableCell>
              <TableCell>
                <LBTLabel variant="bodyText" textAlign="left">
                  Sino alla revoca del consenso per tale finalità
                </LBTLabel>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <LBTLabel variant="bodyText" textAlign="justify">
          Il conferimento dei suoi Dati Personali per la finalità sopra elencate
          è facoltativo.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
        >
          4. DESTINATARI
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          I suoi Dati Personali potranno essere condivisi, per le finalità
          specificate, con:
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item' }}>
              soggetti necessari per l’erogazione dei servizi offerti dal Sito
              tra cui a titolo esemplificativo, l’analisi del funzionamento del
              Sito che agiscono tipicamente in qualità di responsabili del
              trattamento di Laborability;
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              persone autorizzate da Laborability al trattamento dei Dati
              Personali che si siano impegnate alla riservatezza o abbiano un
              adeguato obbligo legale di riservatezza; (es. dipendenti e
              collaboratori di Laborability);
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              autorità giurisdizionali nell’esercizio delle loro funzioni quando
              richiesto dalla Normativa Applicabile.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <BoldSpan>previo suo consenso,</BoldSpan> soggetti terzi affinché
              possano renderla destinataria di comunicazioni commerciali o
              finalizzate alla conclusione di un contratto. Si precisa che i
              soggetti terzi a cui potranno essere comunicati i Suoi dati sono
              società operanti nel settore dei servizi tecnologici e della
              consulenza ai responsabili delle risorse umane e ai lavoratori.
              Tale comunicazione viene resa in adempimento a quanto previsto
              nelle Linee guida in materia di attività promozionale e contrasto
              allo spam del 4 luglio 2013 emanato dall’Autorità Garante per la
              Protezione dei Dari Personali.
            </ListItem>
          </List>
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
        >
          5. TRASFERIMENTI
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          Alcuni dei suoi Dati Personali sono trasferiti a Destinatari che si
          potrebbero trovare al di fuori dello Spazio Economico Europeo.
          Laborability assicura che il trattamento elettronico e cartaceo dei
          suoi Dati Personali da parte dei Destinatari avviene nel rispetto
          della Normativa Applicabile.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
        >
          6. CONSERVAZIONE DEI DATI
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          Laborability tratterà i suoi Dati Personali per il tempo strettamente
          necessario a raggiungere gli scopi indicati al punto 3. A titolo
          esemplificativo, Laborability tratterà i Dati Personali per il tempo
          indicato nella tabella di cui al punto 3.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
        >
          7. I SUOI DIRITTI
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify">
          Ai sensi degli artt. 15 e ss. GDPR, l’utente potrà esercitare i
          seguenti diritti:
          <br />
          <br />
          <u>accesso:</u> conferma o meno che sia in corso un trattamento dei
          dati personali dell’interessato e diritto di accesso agli stessi; non
          è possibile rispondere a richieste manifestamente infondate, eccessive
          o ripetitive;
          <br />
          <u>rettifica:</u> correggere/ottenere la correzione dei dati personali
          se errati o obsoleti e di completarli, se incompleti;
          <br />
          <u>cancellazione/oblio:</u> ottenere, in alcuni casi, la cancellazione
          dei dati personali forniti; questo non è un diritto assoluto, in
          quanto le Società potrebbero avere motivi legittimi o legali per
          conservarli;
          <br />
          <u>limitazione:</u> i dati saranno archiviati, ma non potranno essere
          né trattati, né elaborati ulteriormente, nei casi previsti dalla
          normativa;
          <br />
          <u>portabilità:</u> spostare, copiare o trasferire i dati dai database
          delle Società a terzi. Questo vale solo per i dati forniti
          dall’interessato per l’esecuzione di un contratto o per i quali è
          stato fornito consenso e espresso e il trattamento viene eseguito con
          mezzi automatizzati;
          <br />
          <u>opposizione al marketing diretto;</u>
          <br />
          <u>revoca del consenso</u> in qualsiasi momento, qualora il
          trattamento si basi sul consenso.
          <br />
          <br />
          Ai sensi dell’art. 2-undicies del D.Lgs. 196/2003 (“Decreto”)
          l’esercizio dei diritti dell’interessato può essere ritardato,
          limitato o escluso, con comunicazione motivata e resa senza ritardo, a
          meno che la comunicazione possa compromettere la finalità della
          limitazione, per il tempo e nei limiti in cui ciò costituisca una
          misura necessaria e proporzionata, tenuto conto dei diritti
          fondamentali e dei legittimi interessi dell’interessato, al fine di
          salvaguardare gli interessi di cui al comma 1, lettere a) (interessi
          tutelati in materia di riciclaggio), e) (allo svolgimento delle
          investigazioni difensive o all’esercizio di un diritto in sede
          giudiziaria) ed f) (alla riservatezza dell’identità del dipendente che
          segnala illeciti di cui sia venuto a conoscenza in ragione del proprio
          ufficio). In tali casi, i diritti dell’interessato possono essere
          esercitati anche tramite il Garante con le modalità di cui
          all’articolo 160 dello stesso Decreto. In tale ipotesi, il Garante
          informerà l’interessato di aver eseguito tutte le verifiche necessarie
          o di aver svolto un riesame nonché della facoltà dell’interessato di
          proporre ricorso giurisdizionale
        </LBTLabel>
      </Stack>
    </Section>
  );
}
