import {
  LBTProgressSpinner,
  LBTLabel,
  COLORS,
  LBTSpacer,
  Section,
} from '@laborability/components';

export default function Loader() {
  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={8} isFixed />
      <LBTLabel variant="delaDisplay" component="h1">
        Manca davvero poco
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle">
        Lasciamo che la tecnologia faccia la sua magia
      </LBTLabel>
      <LBTSpacer spacing={6} />
      <LBTProgressSpinner />
      <LBTSpacer spacing={6} />
      <LBTLabel variant="bodyText">
        A breve potrai accedere alla piattaforma ed esplorare più di 200 bonus,
        incentivi e agevolazioni fiscali
      </LBTLabel>
    </Section>
  );
}
