import { useBreakpoint } from '@laborability/commons';
import { Box } from '@mui/material';
import { CSSProperties } from 'react';

interface Props {
  src: string;
  style?: CSSProperties;
}

export default function ImageBox({ src, style }: Props) {
  const { isDesktop } = useBreakpoint();
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <img
        style={{ width: '100%', maxWidth: !isDesktop ? '100%' : '328px' }}
        src={src}
      />
    </Box>
  );
}
