import { CallbackInterface } from 'recoil';
import { setSnackbarError } from '../common';
import { RateService, ReportBenefit, ReportProblem } from '../types';
import { reportLoaderState } from '../state';
import { rateService, reportBenefit, reportProblem } from '../actions';

export const rateServiceCallback =
  ({ set }: CallbackInterface) =>
  async (params: RateService) => {
    set(reportLoaderState, true);
    let res = undefined;
    try {
      res = await rateService(params);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(reportLoaderState, false);
    return res;
  };

export const reportProblemCallback =
  ({ set }: CallbackInterface) =>
  async (params: ReportProblem) => {
    set(reportLoaderState, true);
    let res = undefined;
    try {
      res = await reportProblem(params);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(reportLoaderState, false);
    return res;
  };

export const reportBenefitCallback =
  ({ set }: CallbackInterface) =>
  async (params: ReportBenefit) => {
    set(reportLoaderState, true);
    let res = undefined;
    try {
      res = await reportBenefit(params);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(reportLoaderState, false);
    return res;
  };
