import { Benefit, Misura } from '@laborability/commons';
import {
  COLORS,
  IconEuroComponent,
  IconInboxComponent,
  IconLocationComponent,
  IconsSize,
  IconsStyle,
  LBTListItem,
} from '@laborability/components';
import { List } from '@mui/material';

const BonusListVariantLeft: React.FC<{
  misura?: Misura;
  beneficio?: Benefit;
  isDesktop: boolean;
}> = ({ misura, beneficio, isDesktop }) => {
  return (
    <List
      disablePadding
      sx={{
        order: isDesktop ? 1 : 3,
        marginTop: isDesktop ? 0 : '16px',
      }}
    >
      <LBTListItem
        title={beneficio?.name ?? ''}
        description={beneficio?.description ?? ''}
        alignItems="flex-start"
        leftItem={
          <IconInboxComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        }
      />
      {misura?.final_range && misura?.initial_range && (
        <LBTListItem
          title={`${misura?.initial_range}-${misura?.final_range}€`}
          description="Riceverai una somma compresa in questo intervallo"
          alignItems="flex-start"
          leftItem={
            <IconEuroComponent
              size={IconsSize.LARGE}
              style={IconsStyle.FILLED}
              color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            />
          }
        />
      )}
      <LBTListItem
        title={misura?.national ? 'Nazionale' : 'Regionale'}
        description={
          misura?.national
            ? 'Richiedi questo bonus in tutto il territorio italiano'
            : 'Questo bonus è disponibile in'
        }
        alignItems="flex-start"
        leftItem={
          <IconLocationComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        }
      />
    </List>
  );
};

export default BonusListVariantLeft;
