export default function IconQuestionary() {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.18038 6.04324C6.8768 4.34568 9.3231 3.5 12.3198 3.5C15.3165 3.5 17.7627 4.34569 19.4592 6.04324C21.1556 7.7408 22.0007 10.1887 22.0007 13.1874C22.0007 16.1861 21.1556 18.634 19.4592 20.3316C17.7627 22.0291 15.3165 22.8749 12.3198 22.8749C10.6715 22.8749 9.19056 22.6197 7.90656 22.1057L3.96403 23.4207C2.78204 23.815 1.66755 22.6693 2.09334 21.4976L3.46087 17.7343C2.91142 16.4196 2.63884 14.893 2.63884 13.1874C2.63884 10.1887 3.48396 7.7408 5.18038 6.04324ZM12.5873 9.86309C11.9239 9.73112 11.2655 10.2715 11.2655 10.9478C11.2655 11.5396 10.7858 12.0193 10.1941 12.0193C9.60236 12.0193 9.12266 11.5396 9.12266 10.9478C9.12266 8.9201 11.0166 7.36582 13.0054 7.7614C14.2752 8.01397 15.3054 9.04424 15.558 10.314C15.685 10.9524 15.6115 11.5659 15.3309 12.1179C15.0598 12.6513 14.634 13.0523 14.1765 13.358C13.9851 13.4859 13.8334 13.5898 13.7083 13.6833C13.5825 13.7774 13.5061 13.8446 13.4594 13.8933C13.4522 13.9007 13.4462 13.9072 13.4412 13.9129C13.4092 14.4759 12.9425 14.9226 12.3715 14.9226C11.7798 14.9226 11.3001 14.4429 11.3001 13.8512C11.3001 13.232 11.5743 12.7623 11.9144 12.4084C12.2147 12.0959 12.6179 11.8222 12.986 11.5763C13.2447 11.4034 13.3658 11.2548 13.4207 11.147C13.466 11.0578 13.4974 10.9385 13.4563 10.7321C13.3728 10.3123 13.0071 9.94657 12.5873 9.86309ZM12.3706 16.1322C12.9623 16.1322 13.442 16.6119 13.442 17.2036L13.442 17.5762C13.442 18.168 12.9623 18.6476 12.3706 18.6476C11.7788 18.6476 11.2991 18.168 11.2991 17.5762L11.2991 17.2036C11.2991 16.6119 11.7788 16.1322 12.3706 16.1322Z"
        fill="#282828"
      />
    </svg>
  );
}
