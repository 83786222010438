import { COLORS, LBTLabel, LBTSpacer, Section } from '@laborability/components';
import styled, { CSSProperties } from 'styled-components';
import Dog from '../../assets/images/dog.png';
import Family from '../../assets/images/family.jpeg';
import Mountain from '../../assets/images/mountain.png';
import Square from '../../assets/images/square.png';
import { StaticGrid } from '../../components/StaticGrid';
import { useBreakpoint, useTrackAnalytics } from '@laborability/commons';

const StyledImageContainer = styled('div')(({ style }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...style,
}));

const StyledImage = styled.img<{ maxwidth: string }>`
  width: 100%;
  max-width: ${props => props.maxwidth};
`;
export const Image = ({
  src,
  style,
}: {
  src: string;
  style?: CSSProperties;
}) => {
  const { isDesktop } = useBreakpoint();
  return (
    <StyledImageContainer style={style}>
      <StyledImage src={src} maxwidth={!isDesktop ? '100%' : '328px'} />
    </StyledImageContainer>
  );
};

export interface Article {
  index: number;
  title: string;
  body: string;
  backgroundColor: string;
  image?: string;
  position: { col: number; row: number };
  path: string;
  dataTrack: string;
}

export const ARTICLES: Array<Article> = [
  {
    index: 0,
    title: `Cos'è la cessione del quinto dello stipendio?`,
    body: `La cessione del quinto dello stipendio è un modo con cui un lavoratore estingue un proprio debito. Ciò avviene quando il lavoratore chiede un prestito a un istituto finanziario, offrendo di ripagarlo attraverso una quota fissa dello stipendio dovutogli dal datore di lavoro.`,
    backgroundColor: `${COLORS.getInstance().WHITE}`,
    image: Square,
    position: { col: 0, row: 0 },
    path: 'fifth_01',
    dataTrack: 'cqs_link_1',
  },
  {
    index: 1,
    title: `Cos'è la cessione del quinto dello stipendio?`,
    body: `Lo stipendio, al netto delle ritenute operate su di esso, non può essere ridotto più del quinto per periodi superiori a 10 anni, oltre a ciò bisogna anche considerare che lo stipendio non sia già ridotto da precedenti  pignoramenti o delegazioni di pagamento.`,
    backgroundColor: `${COLORS.getInstance().WHITE}`,
    image: Family,
    position: { col: 1, row: 0 },
    path: 'fifth_02',
    dataTrack: 'cqs_link_2',
  },
  {
    index: 2,
    title: `Cos'è la cessione del quinto dello stipendio?`,
    body: `La cessione dello stipendio non può mai superare il quinto della busta paga. È un limite inderogabile: la ratio della norma è consentire al lavoratore di finanziarsi e di pagare un prestito senza però compromettere la propria situazione finanziaria al di sotto di una determinata soglia.`,
    backgroundColor: `${COLORS.getInstance().BERRY_POPSICLE_LIGHT}`,
    image: undefined,
    position: { col: 2, row: 0 },
    path: 'fifth_03',
    dataTrack: 'cqs_link_3',
  },
  {
    index: 3,
    title: `Cos'è la cessione del quinto dello stipendio?`,
    body: `La cessione del quinto dello stipendio è un modo con cui un soggetto può ripagare un prestito contratto con un istituto finanziario cedendo a questo una parte della propria retribuzione mensile. Le rate coincidono, quindi, con la quota di stipendio ceduta.`,
    backgroundColor: `${COLORS.getInstance().MINT_TONIC_LIGHT}`,
    image: undefined,
    position: { col: 1, row: 1 },
    path: 'fifth_04',
    dataTrack: 'cqs_link_4',
  },
  {
    index: 4,
    title: `Cos'è la cessione del quinto dello stipendio?`,
    body: `Al termine del rapporto è possibile che il lavoratore non abbia ancora finito di pagare interamente il finanziamento ottenuto mediante la cessione del quinto dello stipendio. Per evitare che la cessazione del rapporto pregiudichi l'istituto finanziatore sono previsti precisi obblighi a carico dell'azienda.`,
    backgroundColor: `${COLORS.getInstance().WHITE}`,
    image: Dog,
    position: { col: 1, row: 2 },
    path: 'fifth_05',
    dataTrack: '',
  },
  {
    index: 5,
    title: `Cos'è la cessione del quinto dello stipendio?`,
    body: `La cessione del quinto della pensione è un modo con cui un pensionato estingue un proprio debito. Ciò avviene quando il pensionato chiede un prestito a un istituto finanziario, offrendo di ripagarlo attraverso una quota fissa della pensione dovutagli dall'INPS.`,
    backgroundColor: `${COLORS.getInstance().WHITE}`,
    image: Mountain,
    position: { col: 2, row: 1 },
    path: 'fifth_06',
    dataTrack: 'cqs_link_5',
  },
];

const Fifth = () => {
  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Cessione del quinto' },
    { key: 'page_location', value: '/app/cessione-quinto' },
  ]);

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Cessione del quinto
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        La cessione del quinto è un tipo di prestito per dipendenti e pensionati
        che permette di pagare le rate prelevando fino al 20% dello stipendio o
        della pensione.
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <StaticGrid articles={ARTICLES} />
      <LBTSpacer spacing={4} />
    </Section>
  );
};

export default Fifth;
