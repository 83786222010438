import {
  QUESTIONARY_STEPS,
  QuestionFlow,
  initFlowCallback,
  slugify,
  useBreakpoint,
  useTrackAnalytics,
} from '@laborability/commons';
import {
  LBTAlert,
  LBTButton,
  LBTCheckbox,
  LBTCheckboxButton,
  LBTLabel,
  LBTSpacer,
  QuestionaryContext,
  Section,
} from '@laborability/components';
import { useRecoilCallback } from 'recoil';
import { useContext, useState } from 'react';
import Page from './Page';
import { Box } from '@mui/material';

interface Props {
  flow: QuestionFlow;
}

export default function Flow({ flow }: Props) {
  const { isDesktop } = useBreakpoint();
  const { pageId, setPageId } = useContext(QuestionaryContext);
  const init = useRecoilCallback(initFlowCallback, []);
  const [privacyFlag, setPrivacyFlag] = useState<boolean>(false);

  const initQuestionary = async (id: number) => {
    const res = await init({ id });
    if (!res?.data?.id) return;
    setPageId(res.data.id);
  };
  useTrackAnalytics(
    flow.name
      ? [
          { key: 'event', value: 'page_view' },
          { key: 'page_title', value: `Questionario - ${flow.name}` },
          { key: 'page_location', value: `/onboarding/${slugify(flow.name)}` },
        ]
      : [],
  );

  console.log('###flow', flow);

  if (!pageId)
    return (
      <Section>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="sourceSubtitle2">
          Passo {flow.step}/{QUESTIONARY_STEPS}
        </LBTLabel>
        <LBTSpacer spacing={1} />
        <LBTLabel variant="delaDisplay">{flow.name}</LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle">{flow.description}</LBTLabel>
        <LBTSpacer spacing={2} />
        {flow?.image && (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: '100%',
                maxWidth: !isDesktop ? '200px' : '300px',
              }}
              src={flow.image}
              alt=""
            />
          </Box>
        )}
        <LBTSpacer spacing={2} />
        <LBTAlert
          title={flow?.info_title!}
          message={flow?.info_text!}
          variant="standard"
          color="success"
          sx={{ width: '100%' }}
        />
        <LBTSpacer spacing={4} />
        {flow?.step === 1 && (
          <>
            <LBTCheckbox
              label="Ho letto l'informativa ex art 13 e do il mio consenso"
              checked={privacyFlag}
              onChange={() => {
                setPrivacyFlag(!privacyFlag);
              }}
            />
            <LBTSpacer spacing={4} />
          </>
        )}

        <LBTButton
          onClick={() => initQuestionary(flow.id!)}
          variant="contained"
          size="large"
          maxWidth="504px"
          fullWidth
          disabled={flow?.step === 1 ? !privacyFlag : false}
        >
          Avanti
        </LBTButton>
        <LBTSpacer spacing={4} />
      </Section>
    );

  // if (flow?.step === 3) {
  //   return <></>;
  // }
  return <Page flow={flow} />;
}
