import { COLORS } from '../../utils';
import StyledIcon from './StyledIcon';

interface Props {
  datatestid: string;
}

export default function IconBurger({ datatestid = '' }: Props) {
  return (
    <StyledIcon
      datatestid={`lbt-icon-${datatestid}`}
      data-track={datatestid}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00098 4.1087C2.00098 3.81465 2.11513 3.53265 2.31833 3.32473C2.52152 3.11681 2.79712 3 3.08448 3H20.9175C21.2048 3 21.4804 3.11681 21.6836 3.32473C21.8868 3.53265 22.001 3.81465 22.001 4.1087C22.001 4.40274 21.8868 4.68474 21.6836 4.89266C21.4804 5.10058 21.2048 5.21739 20.9175 5.21739H3.08448C2.79712 5.21739 2.52152 5.10058 2.31833 4.89266C2.11513 4.68474 2.00098 4.40274 2.00098 4.1087ZM2.00098 11.5C2.00098 11.206 2.11513 10.924 2.31833 10.716C2.52152 10.5081 2.79712 10.3913 3.08448 10.3913H20.9175C21.2048 10.3913 21.4804 10.5081 21.6836 10.716C21.8868 10.924 22.001 11.206 22.001 11.5C22.001 11.794 21.8868 12.076 21.6836 12.284C21.4804 12.4919 21.2048 12.6087 20.9175 12.6087H3.08448C2.79712 12.6087 2.52152 12.4919 2.31833 12.284C2.11513 12.076 2.00098 11.794 2.00098 11.5ZM3.08448 17.7826C2.79712 17.7826 2.52152 17.8994 2.31833 18.1073C2.11513 18.3153 2.00098 18.5973 2.00098 18.8913C2.00098 19.1854 2.11513 19.4674 2.31833 19.6753C2.52152 19.8832 2.79712 20 3.08448 20H20.9175C21.2048 20 21.4804 19.8832 21.6836 19.6753C21.8868 19.4674 22.001 19.1854 22.001 18.8913C22.001 18.5973 21.8868 18.3153 21.6836 18.1073C21.4804 17.8994 21.2048 17.7826 20.9175 17.7826H3.08448Z"
        fill={COLORS.getInstance().BW_GREYS_JET_BLACK}
      />
    </StyledIcon>
  );
}
