export const IconGenitorialita = ({ locked = false }: { locked?: boolean }) => {
  return locked ? (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5002 15.3452C25.0713 15.3452 24.6458 15.3538 24.2244 15.3692C18.5906 15.5752 13.866 20.2426 13.6767 25.8065C13.6635 26.1939 13.6562 26.5846 13.6562 26.9782C13.6562 28.3384 13.743 29.6671 13.8619 30.9489C14.2246 34.8592 17.4345 38.0208 21.3879 38.3935C22.7134 38.5184 24.0899 38.6111 25.5002 38.6111C26.9105 38.6111 28.287 38.5184 29.6125 38.3935C33.5658 38.0208 36.7756 34.8592 37.1384 30.9489C37.2574 29.6671 37.3441 28.3384 37.3441 26.9782C37.3441 26.5846 37.3368 26.1939 37.3236 25.8065C37.1345 20.2426 32.4098 15.5752 26.776 15.3692C26.3545 15.3538 25.9291 15.3452 25.5002 15.3452Z"
        fill="#9B9B9B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.323 28.1674C35.091 29.1673 32.8128 30.066 30.6951 30.9014L30.6654 30.9131C27.5834 32.1289 23.4844 32.1254 20.4063 30.9201L20.1831 30.8328C18.1051 30.0192 15.8669 29.143 13.6773 28.1667C13.6637 27.7734 13.6562 27.377 13.6562 26.978C13.6562 26.5844 13.6635 26.1937 13.6767 25.8063C13.6999 25.1236 13.7914 24.4543 13.9446 23.8051C16.4635 25.0031 19.1592 26.0586 21.7704 27.081L21.9256 27.1417C24.0299 27.9657 27.0381 27.9662 29.1364 27.1385C31.7755 26.0974 34.5008 25.021 37.0554 23.8036C37.209 24.4532 37.3006 25.123 37.3236 25.8063C37.3368 26.1937 37.3441 26.5844 37.3441 26.978C37.3441 27.3773 37.3368 27.7739 37.323 28.1674Z"
        fill="#D4D4D4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.35801 17.845C9.35801 16.498 8.25145 15.406 6.88647 15.406C5.52149 15.406 4.41493 16.498 4.41493 17.845V33.0947C3.25881 33.8742 2.5 35.1854 2.5 36.6716C2.5 39.0621 4.46388 41 6.88644 41C9.30901 41 11.2729 39.0621 11.2729 36.6716C11.2729 35.1854 10.5141 33.8742 9.35801 33.0948V17.845Z"
        fill="#D4D4D4"
      />
      <path
        d="M5.75554 15.5812C4.08079 16.6183 4.08177 18.105 5.74898 19.1451C8.05707 20.585 10.4433 22.0802 13.216 23.4506C15.9887 24.821 19.014 26.0004 21.9272 27.1411C24.0315 27.9651 27.0397 27.9656 29.138 27.1379C32.0289 25.9975 35.0231 24.8148 37.7833 23.4506C40.5435 22.0864 42.9363 20.6065 45.2438 19.1777C46.9185 18.1406 46.9175 16.6538 45.2503 15.6138C42.9423 14.1739 40.5561 12.6787 37.7833 11.3083C35.0106 9.93788 31.9853 8.75851 29.0721 7.61776C26.9678 6.79376 23.9596 6.79328 21.8613 7.62101C18.9704 8.76137 15.9762 9.9441 13.216 11.3083C10.4558 12.6725 8.06284 14.1524 5.75554 15.5812Z"
        fill="#9B9B9B"
      />
    </svg>
  ) : (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3241_1001)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.8976 1C28.4611 1 28.0426 1.17504 27.734 1.4866C27.4253 1.79817 27.252 2.22075 27.252 2.66137V15.9523C27.252 16.393 27.4253 16.8155 27.734 17.1271C28.0426 17.4387 28.4611 17.6137 28.8976 17.6137C29.334 17.6137 29.7526 17.4387 30.0612 17.1271C30.3699 16.8155 30.5432 16.393 30.5432 15.9523H43.7084C43.7084 16.393 43.8818 16.8155 44.1904 17.1271C44.499 17.4387 44.9176 17.6137 45.354 17.6137C45.7905 17.6137 46.2091 17.4387 46.5177 17.1271C46.8263 16.8155 46.9997 16.393 46.9997 15.9523C46.9997 13.9888 46.6166 12.0444 45.8723 10.2303C45.128 8.41622 44.037 6.76789 42.6617 5.37944C41.2864 3.99098 39.6537 2.8896 37.8567 2.13818C36.0598 1.38675 34.1339 1 32.1889 1L28.8976 1Z"
          fill="#FFA084"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.20943 8.06402C9.13166 7.62868 8.93729 7.22315 8.6474 6.89142C8.35751 6.5597 7.98317 6.31446 7.56501 6.1823C7.14684 6.05013 6.70081 6.0361 6.2753 6.14172C5.84979 6.24735 5.46106 6.46859 5.15127 6.78145C4.68334 7.22164 4.06442 7.46129 3.42492 7.4499C2.78542 7.43851 2.17526 7.17697 1.723 6.72038C1.27073 6.26379 1.01167 5.64781 1.00038 5.00219C0.989101 4.35658 1.22648 3.73175 1.66251 3.25934C5.84903 -0.973829 13.0175 1.29892 14.0674 7.18017L15.6307 15.9522H47V19.2749C47 24.6711 44.1432 28.1134 40.4503 30.0938C36.8595 32.021 32.4492 32.6157 28.8979 32.5659C25.3499 32.6157 20.9396 32.0243 17.3455 30.0938C13.6593 28.1134 10.7958 24.6711 10.7958 19.2749V17.6136C10.796 17.4826 10.8114 17.3521 10.8419 17.2248L9.20943 8.06402Z"
          fill="#386CCC"
        />
        <path
          d="M19.0242 46.9999C22.9079 46.9999 25.0933 44.7936 25.0933 40.8727C25.0933 36.9519 22.9079 34.7456 19.0242 34.7456C15.1405 34.7456 12.9551 36.9519 12.9551 40.8727C12.9551 44.7936 15.1405 46.9999 19.0242 46.9999Z"
          fill="#80ABF9"
        />
        <path
          d="M38.7713 46.9999C42.655 46.9999 44.8404 44.7936 44.8404 40.8727C44.8404 36.9519 42.655 34.7456 38.7713 34.7456C34.8876 34.7456 32.7021 36.9519 32.7021 40.8727C32.7021 44.7936 34.8876 46.9999 38.7713 46.9999Z"
          fill="#80ABF9"
        />
      </g>
      <defs>
        <clipPath id="clip0_3241_1001">
          <rect
            width="46"
            height="46"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
