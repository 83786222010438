const IconUnlockComponent = ({ color }: { color: string }) => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00009 10.9003V12.1002M8.47478 2.52534C7.8184 1.86894 6.92814 1.50018 5.99986 1.50018C5.07158 1.50018 4.18132 1.86894 3.52493 2.52534C2.86854 3.18172 2.49978 4.07198 2.49978 5.00026V7.53781M5.99999 16.4998C9.19998 16.4998 11 14.6998 11 11.4998C11 8.29983 9.19998 6.49984 5.99999 6.49984C2.8 6.49984 1 8.29983 1 11.4998C1 14.6998 2.8 16.4998 5.99999 16.4998Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconUnlockComponent;
