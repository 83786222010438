import { LBTSpacer, Section } from '@laborability/components';
import useExternalScript from '../../hooks/useExternalScript';
import styled from 'styled-components';

const StyledFrame = styled('div')(({ style }) => ({
  width: '100%',
  height: '100vh',
  border: 'none',
  ...style,
}));

export default function SeverancePay() {
  useExternalScript(
    'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
  );

  const markup = {
    __html: `
    <iframe
      title="Severance Pay"
      style="width: 100%; height: 100vh; border: none;"
      src="https://meetings-eu1.hubspot.com/hello-elsa/due-chiacchiere-con-elsa-premium?embed=true"
    ></iframe>`,
  };

  return (
    <Section>
      <LBTSpacer spacing={2} />

      <StyledFrame dangerouslySetInnerHTML={markup} />

      <LBTSpacer spacing={8} isFixed />
    </Section>
  );
}
