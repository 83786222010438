import { FONTS } from '@laborability/components';
import * as React from 'react';
import styled from 'styled-components';

export type StyledSpanProps = {
  fontWeight?: number;
  children: string;
};

const StyledSpan = styled('span')<{
  fontFamily: string;
  fontWeight?: number;
}>(({ fontWeight, fontFamily }) => ({
  fontWeight,
  fontFamily,
}));

const BoldSpan: React.FC<StyledSpanProps> = ({
  fontWeight = 400,
  children,
}: StyledSpanProps) => {
  return (
    <StyledSpan fontFamily={FONTS.SOURCE_SANS_PRO_BOLD} fontWeight={fontWeight}>
      {children}
    </StyledSpan>
  );
};

export default BoldSpan;
