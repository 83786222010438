import { useBreakpoint } from '@laborability/commons';
import {
  COLORS,
  LBTButton,
  UnstyledButton,
  LBTLabel,
  IconFacebook,
  IconInstragram,
  IconLinkedin,
  IconYoutube,
} from '@laborability/components';
import { Stack, Divider, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../Routes';

export type LBTFooterProps = {};

const NewsletterDescription = () => (
  <LBTLabel
    component="small"
    variant="bodyText"
    color={COLORS.getInstance().PRIMARY_MAIN}
  >
    Ricevi tutte le novità nella tua casella di posta
  </LBTLabel>
);

const ProjectDescription = () => (
  <LBTLabel
    component="small"
    variant="sourceCaption"
    color={COLORS.getInstance().PRIMARY_MAIN}
  >
    LABORABILITY FOR YOU® è un progetto di LABORABILITY | P.IVA IT05372450287 |{' '}
    <Link
      to={{ pathname: APP_ROUTES.PRIVACY_POLICY }}
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      Privacy Policy
    </Link>{' '}
    |{' '}
    <Link
      to={{ pathname: APP_ROUTES.COOKIE_POLICY }}
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      Cookie Policy
    </Link>
  </LBTLabel>
);

const SocialIcons = () => {
  return (
    <Stack spacing={'16px'} direction={'row'} alignItems={'center'}>
      <UnstyledButton
        onClick={() => window.open('https://www.facebook.com/laborability')}
      >
        <IconFacebook />
      </UnstyledButton>
      <UnstyledButton
        onClick={() => window.open('https://www.youtube.com/@laborability')}
      >
        <IconYoutube />
      </UnstyledButton>
      <UnstyledButton
        onClick={() => window.open('https://www.instagram.com/laborability/')}
      >
        <IconInstragram />
      </UnstyledButton>
      <UnstyledButton
        onClick={() =>
          window.open('https://www.linkedin.com/company/laborability/')
        }
      >
        <IconLinkedin />
      </UnstyledButton>
    </Stack>
  );
};

const ButtonNewsletter = () => (
  <LBTButton
    variant="contained"
    color="primary"
    size="large"
    onClick={() => console.log('TODO: navigate to newsletter page')}
    children="Iscriviti alla newsletter"
  ></LBTButton>
);

const MobileFooter = () => (
  <Stack
    sx={{
      background: COLORS.getInstance().BW_GREYS_JET_BLACK,
      width: '100%',
    }}
    justifyContent="center"
    alignItems="center"
  >
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      width={'100%'}
      marginTop={'32px'}
    >
      {/* <NewsletterDescription /> */}
      {/* <ButtonNewsletter /> */}
      <SocialIcons />
      <Divider
        sx={{
          borderWidth: '1px',
          borderColor: COLORS.getInstance().PRIMARY_MAIN,
          margin: '32px 16px',
        }}
        orientation="horizontal"
        flexItem
      />
      <LBTLabel
        component="small"
        variant="sourceCaption"
        color={COLORS.getInstance().PRIMARY_MAIN}
      >
        LABORABILITY FOR YOU® è un progetto di LABORABILITY
      </LBTLabel>
      <LBTLabel
        component="small"
        variant="sourceCaption"
        color={COLORS.getInstance().PRIMARY_MAIN}
      >
        P.IVA IT05372450287 | Privacy Policy | Cookie Policy
      </LBTLabel>
      <Box component="div" sx={{ marginBottom: '32px' }} />
    </Stack>
  </Stack>
);

const DesktopDivider = () => (
  <Divider
    sx={{ borderWidth: '1px', borderColor: COLORS.getInstance().PRIMARY_MAIN }}
    orientation="vertical"
    flexItem
  />
);
const DesktopFooter = () => (
  <Stack
    sx={{
      background: COLORS.getInstance().BW_GREYS_JET_BLACK,
      height: '100px',
      width: '100%',
    }}
    justifyContent="center"
    alignItems="center"
  >
    <Stack
      sx={{ maxWidth: '1032px' }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width={'100%'}
    >
      <ProjectDescription />
      {/* <DesktopDivider /> */}
      <SocialIcons />
      {/* <DesktopDivider /> */}
      {/* <ButtonNewsletter /> */}
    </Stack>
  </Stack>
);
const LBTFooter: React.FC<LBTFooterProps> = () => {
  const { isDesktop } = useBreakpoint();
  return isDesktop ? <DesktopFooter /> : <MobileFooter />;
};

export default LBTFooter;
