import { COLORS } from '../../utils';
import StyledIcon from './StyledIcon';

interface Props {
  datatestid: string;
}

export default function IconCross({ datatestid = '' }: Props) {
  return (
    <StyledIcon
      datatestid={`lbt-icon-${datatestid}`}
      data-track={datatestid}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5483 21.549C19.8176 21.8091 20.1784 21.9531 20.5529 21.9498C20.9274 21.9466 21.2857 21.7964 21.5505 21.5316C21.8153 21.2667 21.9655 20.9085 21.9688 20.534C21.9721 20.1595 21.8281 19.7987 21.5679 19.5293L14.0292 11.9907L21.5822 4.43914C21.8502 4.17132 22.0008 3.808 22.001 3.42911C22.0011 3.05022 21.8507 2.6868 21.5829 2.41879C21.3151 2.15078 20.9518 2.00013 20.5729 2C20.194 1.99987 19.8306 2.15025 19.5625 2.41807L12.0096 9.97101L4.44238 2.40379C4.173 2.14361 3.8122 1.99964 3.4377 2.0029C3.0632 2.00615 2.70496 2.15637 2.44014 2.42119C2.17532 2.68601 2.0251 3.04425 2.02185 3.41875C2.0186 3.79325 2.16256 4.15405 2.42274 4.42343L9.98854 11.9907L2.43702 19.5436C2.30061 19.6754 2.19179 19.833 2.11694 20.0072C2.04208 20.1815 2.00268 20.3689 2.00103 20.5586C1.99938 20.7482 2.03552 20.9363 2.10734 21.1118C2.17916 21.2874 2.28521 21.4468 2.41932 21.5809C2.55343 21.715 2.7129 21.8211 2.88844 21.8929C3.06397 21.9647 3.25205 22.0009 3.4417 21.9992C3.63136 21.9976 3.81878 21.9582 3.99304 21.8833C4.1673 21.8085 4.32491 21.6997 4.45667 21.5632L12.0096 14.0117L19.5483 21.5504V21.549Z"
        fill={COLORS.getInstance().BW_GREYS_JET_BLACK}
      />
    </StyledIcon>
  );
}
