import { Misura } from '@laborability/commons';
import {
  COLORS,
  IconCalendarComponent,
  IconEnteComponent,
  IconsSize,
  IconsStyle,
  LBTListItem,
} from '@laborability/components';
import { List } from '@mui/material';
import dayjs from 'dayjs';

const BonusListVariantRight: React.FC<{
  misura?: Misura;
  isDesktop: boolean;
}> = ({ misura, isDesktop }) => {
  const startDate = dayjs(misura?.start_date, 'DD-MM-YYYY HH:mm:SS').format(
    'DD/MM/YYYY',
  );
  const endDate = dayjs(misura?.end_date, 'DD-MM-YYYY HH:mm:SS').format(
    'DD/MM/YYYY',
  );

  return (
    <List
      disablePadding
      sx={{
        order: isDesktop ? 1 : 3,
        marginTop: isDesktop ? 0 : '16px',
      }}
    >
      <LBTListItem
        title={`Dal ${startDate} al ${endDate}`}
        description="Il bonus è valido in questo periodo"
        alignItems="flex-start"
        leftItem={
          <IconCalendarComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        }
      />
      <LBTListItem
        title={misura?.agency?.name ?? ''}
        description={`Il bonus è gestito da un ente ${misura?.agency?.is_public_agency ? 'pubblico' : 'privato'}`}
        alignItems="flex-start"
        leftItem={
          <IconEnteComponent
            size={IconsSize.LARGE}
            style={IconsStyle.FILLED}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          />
        }
      />
    </List>
  );
};

export default BonusListVariantRight;
