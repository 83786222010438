import {
  COLORS,
  LBTAccordion,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { SolaryFits } from '../../components/SolaryFits';
import { StaticArticles } from '../../components/StaticArticles';
import BoldSpan from '../../components/answerBoxes/BoldSpan';
import {
  AnswerBoxOne,
  AnswerBoxThree,
  AnswerBoxTwo,
} from '../../components/answerBoxes/FifthSalaryTerminationSecondBoxes';
import { ARTICLES, Image } from './Fifth';
import { useTrackAnalytics } from '@laborability/commons';

const ACCORDION_ITEMS = [
  {
    question: 'Come richiedere la cessione del quinto',
    answer: <AnswerBoxOne />,
  },
  {
    question: 'Quale documentazione bisogna presentare?',
    answer: <AnswerBoxTwo />,
  },
  {
    question: 'Chi non può cedere il quinto dello stipendio?',
    answer: <AnswerBoxThree />,
  },
];

const Fifth02 = () => {
  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Cessione del quinto - Articolo 2' },
    { key: 'page_location', value: '/app/cessione-quinto/articolo-2' },
  ]);

  return (
    <>
      <Section backgroundColor={COLORS.getInstance().TANGERINE_SUPERLIGHT}>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Cessione del quinto, quanto posso chiedere?
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Pagare un debito cedendo un quinto dello stipendio è una pratica
          diffusa, ecco come si chiede e quanto si può ottenere
        </LBTLabel>
        <LBTSpacer spacing={4} />
        {ARTICLES[1].image && <Image src={ARTICLES[1].image} />}

        <LBTLabel
          variant="bodyText"
          textAlign="left"
          paddingBottom={'16px'}
          paddingTop={'48px'}
        >
          Lo <BoldSpan>stipendio, al netto delle ritenute</BoldSpan> operate su
          di esso, non può essere ridotto più del quinto per periodi superiori a
          10 anni, oltre a ciò bisogna anche considerare che lo stipendio non
          sia già ridotto da precedenti pignoramenti o delegazioni di pagamento.
          <LBTSpacer spacing={2} isFixed />
          <BoldSpan>Esempio</BoldSpan> Ipotizzando che un lavoratore percepisca
          uno stipendio netto annuo 20.000 euro in 14 mensilità, senza che vi
          siano ulteriori trattenute a titolo di pignoramento o delegazioni di
          pagamento, il lavoratore a tempo indeterminato potrebbe ottenere fino
          a 333,33 euro mensili per 10 anni. 20.000,00/12=1.666,661.666,66 x
          20%= 333,33 euro netti (quota massima cedibile)
        </LBTLabel>
        {ACCORDION_ITEMS.map((item, index) => (
          <LBTAccordion
            key={index}
            uniqueKey={`${index}`}
            details={item.answer}
            summary={item.question}
          />
        ))}
        <LBTSpacer spacing={4} />
      </Section>

      <SolaryFits></SolaryFits>

      <Section backgroundColor={COLORS.getInstance().WHITE}>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h3">
          Vai ai prossimi articoli
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <StaticArticles toExclude={[1]} />
      </Section>
    </>
  );
};

export default Fifth02;
