import { KeycloakContext } from '@laborability/components';
import { useContext, useEffect } from 'react';

export default function Logout() {
  const { keycloak } = useContext(KeycloakContext);

  useEffect(() => {
    keycloak?.logout();
  }, []);

  return null;
}
